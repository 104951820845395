import theme from '@/theme.ts'
import Modal from '@mui/material/Modal'
import { Box, Stack, Typography } from '@mui/material'
import add_vendor_to_rfp_illustration from '@/assets/add-vendors-to-rfp-illustration.png'
import { AccountCircleOutlined } from '@mui/icons-material'

interface RFPAddVendorHelperModalProps {
  containerId: string
  open: boolean
  onClose: (arg: boolean) => void
}
export const RFPAddVendorHelperModal = ({ containerId, open, onClose }: RFPAddVendorHelperModalProps) => {
  return (
    <Modal
      open={open}
      onClose={() => onClose(false)}
      closeAfterTransition
      container={() => document.getElementById(containerId)}
      slotProps={{
        backdrop: {
          sx: {
            position: 'absolute',
            boxShadow: '0px 4px 44px 0px rgba(0, 0, 0, 0.2)',
            backgroundColor: 'rgba(255,255,255,0.5)',
          },
        },
      }}
      aria-labelledby="add-plants-modal-title"
      aria-describedby="add-plants-modal-description"
      sx={{
        position: 'absolute',
        width: '100%',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          width: '40%',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 0,
          outline: 0,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <Box
          component="img"
          src={add_vendor_to_rfp_illustration}
          alt="add_vendor_to_rfp_modal_illustration"
          sx={{
            pt: 3,
            pl: 3,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            minHeight: '200px',
          }}
        />
        <Stack sx={{ p: 3, gap: 2 }}>
          <Stack direction="row" sx={{ gap: 1 }}>
            <AccountCircleOutlined />
            <Typography variant="body2" fontWeight={700} sx={{ alignContent: 'center' }}>
              Add vendors to this RFP
            </Typography>
          </Stack>
          <Typography variant="body1">
            Once you add vendors you'll be able to customize the plants and quantity for each
          </Typography>
        </Stack>
      </Box>
    </Modal>
  )
}
