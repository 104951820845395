import Box from '@mui/material/Box'
import { RFPDetailsProps } from '@/components/rfps/types.ts'
import { formatStringToFullMonthDayYear, UserFullName } from '@/lib/utils.ts'
import { DetailsSection } from '@/components/ui/details-section.tsx'
import { DetailsSectionField, DetailsSectionProps } from '@/types.ts'
import { DetailsSectionText } from '@/components/ui/details-section-text.tsx'
import Typography from '@mui/material/Typography'
import { Stack } from '@mui/material'
import { ClearTextIconButton } from '@/components/ui/base/buttons/text-icon-buttons.tsx'
import EditOutlined from '@mui/icons-material/EditOutlined'

export const RFPDetails = ({ rfpData, editMode, openEditRfpModal }: RFPDetailsProps) => {
  const ownerData = (): DetailsSectionField => {
    const defaultText = 'None'
    const fullName = UserFullName(rfpData?.owner) || defaultText
    const content = <DetailsSectionText text={fullName} />
    return { label: 'Owner', node: content }
  }

  const collaboratorsData = (): DetailsSectionField => {
    const defaultText = 'None'
    const content =
      rfpData?.collaborators.length > 0
        ? rfpData.collaborators.map((collaborator: any) => {
            return <Box>{collaborator?.email}</Box>
          })
        : defaultText
    return { label: 'Collaborators', node: content }
  }

  const quoteData = (): DetailsSectionField => {
    const defaultText = 'TBD'
    const formattedDate = formatStringToFullMonthDayYear(rfpData.quote_needed_by) || defaultText
    const content = <DetailsSectionText text={formattedDate} />
    return { label: 'Quote Needed By', node: content }
  }

  const deliveryData = (): DetailsSectionField => {
    const defaultText = 'TBD'
    const formattedDate = formatStringToFullMonthDayYear(rfpData.delivery_date) || defaultText
    const content = <DetailsSectionText text={formattedDate} />
    return { label: 'Delivery Date', node: content }
  }

  const detailsDataTitle = (): DetailsSectionField => {
    const content = (
      <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          RFP Details
        </Typography>
        {editMode && (
          <Stack direction="row">
            <ClearTextIconButton
              text="Edit"
              onClick={openEditRfpModal}
              startIcon={<EditOutlined />}
              sx={{ p: 0, color: '#ccc' }}
            />
          </Stack>
        )}
      </Stack>
    )
    return { label: 'RFP Details', node: content }
  }

  const detailsData = {
    title: detailsDataTitle(),
    fields: [ownerData(), collaboratorsData(), quoteData(), deliveryData()],
    defaultNode: null,
  } as DetailsSectionProps

  return DetailsSection(detailsData)
}
