import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import { type MouseEvent } from 'react'

import Checkbox from '@mui/material/Checkbox'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'

import DragIndicatorIcon from '@mui/icons-material/DragIndicator'

import PlantItemSpecs from '../plant-list-importer/plant-item-specs.tsx'
import InlineEditableRangeDimension from '../plant-list-importer/inline-editable-range-dimension.tsx'
import InlineEditableTextField from '../plant-list-importer/inline-editable-text-field.tsx'
import PlantListEntryActions from './plant-list-entry-actions.tsx'
import type { PlantListEntry, RangeDimension } from '@/types.ts'
import Chip from '@mui/material/Chip'

type SortablePlantItemProps = {
  plant: PlantListEntry
  isSelected: boolean
  onSelect: () => void
  onUpdate: (updatedPlant: PlantListEntry) => void
  onEditEntry: (entry: PlantListEntry) => void
  optionsEnums: Record<string, any> | undefined
  testId?: string
}
export default function PlantListDetailTableItem({
  plant,
  isSelected,
  onSelect,
  onUpdate,
  onEditEntry,
  optionsEnums,
}: SortablePlantItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: plant.id })

  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }

  const handleCheckboxClick = (event: MouseEvent) => {
    event.stopPropagation()
    onSelect()
  }

  const handlePropClick = (event: MouseEvent) => {
    event.stopPropagation()
  }

  const handleQuantityUpdate = (newValue: RangeDimension) => {
    onUpdate({ ...plant, quantity_count: newValue })
  }

  const handleCommonNameUpdate = (newValue: string | null) => {
    onUpdate({ ...plant, common_name: newValue })
  }

  const handleEdit = () => {
    onEditEntry(plant)
  }

  return (
    <>
      <TableRow ref={setNodeRef} style={style} hover id={plant.id}>
        <TableCell padding="none" style={{ width: '48px' }}>
          <div {...attributes} {...listeners} className="flex h-full cursor-move items-center justify-center">
            <DragIndicatorIcon />
          </div>
        </TableCell>
        <TableCell padding="checkbox" onClick={handleCheckboxClick}>
          <Checkbox checked={isSelected} />
        </TableCell>
        <TableCell align="left">
          <InlineEditableTextField
            value={plant.common_name || plant.scientific_name}
            onClick={handlePropClick}
            onSave={handleCommonNameUpdate}
          />
        </TableCell>
        <TableCell align="center">
          <InlineEditableRangeDimension
            value={plant.quantity_count}
            onSave={handleQuantityUpdate}
            onClick={handlePropClick}
            truncateRange={true}
          />
        </TableCell>
        <TableCell align="left">
          <PlantItemSpecs entry={plant} onUpdate={onUpdate} optionsEnums={optionsEnums} />
        </TableCell>
        <TableCell align="right">
          <Chip
            sx={{
              backgroundColor: 'grey.400',
              color: 'white',
              fontWeight: 'bold',
              fontSize: '0.75em',
            }}
            label="UNASSIGNED"
          />
        </TableCell>
        <TableCell padding="none" align="right">
          <PlantListEntryActions onEdit={handleEdit} />
        </TableCell>
      </TableRow>
    </>
  )
}
