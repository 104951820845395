import { type ClassValue, clsx } from 'clsx'
import { format, isBefore, parse, parseISO } from 'date-fns'
import { twMerge } from 'tailwind-merge'
import { weekDays, allDays, OrganizationPermissionTypes, ContactPhoneTypes, ContactEmailTypes } from '@/constants.ts'
import { MAX_IMAGE_DIMENSION } from '@/constants.ts'
import {
  Contact,
  ContactDetails,
  OrganizationCardDetails,
  GCSFile,
  MeasurableRangeDimension,
  OrganizationMember,
  RangeDimension,
  User,
  UserProfile,
  Organization,
  UserContact,
} from '@/types.ts'
import { initialFormAddress } from '@/seed_form_data.ts'
import { AsYouType, CountryCode, parsePhoneNumber, parsePhoneNumberWithError } from 'libphonenumber-js'

export function cn(...inputs: ClassValue[]) {
  return twMerge(clsx(inputs))
}

export function generateObjectId(m = Math, d = Date, h = 16, s = (s: number) => m.floor(s).toString(h)) {
  return s(d.now() / 1000) + ' '.repeat(h).replace(/./g, () => s(m.random() * h))
}

export function isExpired(date: Date | string) {
  let dateToCheck = date
  if (typeof dateToCheck === 'string') {
    dateToCheck = new Date(dateToCheck)
  }
  const now = new Date()

  return isBefore(dateToCheck, now)
}

export function abbreviateUnit(unit: string) {
  switch (unit) {
    case 'square feet':
      return 'sq ft'
    case 'square inch':
      return 'sq in'
    case 'inch':
      return 'in'
    case 'feet':
      return 'ft'
    case 'gallon':
      return 'gal'
    default:
      return unit
  }
}

export function formatDimension(
  dimension: MeasurableRangeDimension | RangeDimension | null | undefined,
  truncateRange?: boolean
): string {
  if (!dimension || (dimension.min === null && dimension.max === null)) {
    return ''
  }
  const min = dimension.min === null ? '' : dimension.min
  const max = dimension.max === null ? '' : dimension.max
  let unit = ''
  if ('unit' in dimension) {
    unit = abbreviateUnit(dimension.unit || '')
  }

  if (min === max || !max || truncateRange) {
    return `${min} ${unit}`
  }

  if (!min) {
    return `${max} ${unit}`
  }

  return `${min}-${max} ${unit}`
}

export function isImageFile(file: File) {
  return file.type.startsWith('image/')
}
export function checkImageDimensions(file: File): Promise<boolean> {
  return new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (e) => {
      const img = new Image()
      img.onload = () => {
        resolve(img.width <= MAX_IMAGE_DIMENSION && img.height <= MAX_IMAGE_DIMENSION)
      }
      img.src = e.target?.result as string
    }
    reader.readAsDataURL(file)
  })
}

export function convertContactToContactDetails(contact: Contact): ContactDetails {
  const contactDetails: ContactDetails = {
    id: contact?.id || '',
    organization_name: contact.organization_name,
    first_name: contact.first_name,
    last_name: contact.last_name,
    address: contact.address ? contact.address : [initialFormAddress],
    phone: contact.phone || [],
    email: contact.email || [],
    type: contact.type,
  }

  return contactDetails
}

export function trimValues(obj: any): any {
  if (typeof obj !== 'object' || obj === null) {
    return typeof obj === 'string' ? obj.trim() : obj
  }
  return Array.isArray(obj)
    ? obj.map(trimValues)
    : Object.fromEntries(Object.entries(obj).map(([key, value]) => [key, trimValues(value)]))
}

export function removeItemByIndex(array: object[], index: number): object[] {
  if (index >= 0 && index < array.length) {
    array.splice(index, 1)
  }
  return array
}

export function countryFormattedPhoneNumber(value: string, country?: CountryCode) {
  const defaultCountry: CountryCode = 'US'

  if (!value) {
    return value
  }

  if (value.startsWith('+')) {
    try {
      const phoneNumber = parsePhoneNumber(value)
      if (phoneNumber) {
        if (phoneNumber.country) {
          const sliceLength = phoneNumber.countryCallingCode.length + 1
          const slicedValue = value.slice(sliceLength).trim()
          const asYouTypePhoneNumber = new AsYouType(phoneNumber.country)
          return `+${phoneNumber.countryCallingCode} ${asYouTypePhoneNumber.input(slicedValue)}`
        }
      }
    } catch (error) {
      console.error('invalid number', error)
    }
  } else {
    const asYouTypePhoneNumber = new AsYouType(country || defaultCountry)
    return '+1 ' + asYouTypePhoneNumber.input(value)
  }

  return value
}

export function validatePhoneNumber(value: string, country?: CountryCode) {
  const defaultCountry: CountryCode = 'US'
  try {
    const phoneNumber = parsePhoneNumberWithError(value, country || defaultCountry)
    return phoneNumber.isValid()
  } catch (error) {
    console.error('Error parsing phone number:', error)
    return false
  }
}

export function convertUserToUserProfile(user: User): UserProfile {
  return {
    id: user.id,
    first_name: user.first_name,
    last_name: user.last_name,
    email: user.email,
    title: user.title,
    biography: user.biography,
    address: user.address,
    phone: user.phone,
    other_email: user.other_email,
    profile_picture: (user?.profile_picture as GCSFile) || null,
    timezone: (user?.timezone as string) || null,
  }
}

export const convert_timestamp_to_12hr_format = (timestamp: string) => {
  try {
    const date = new Date(timestamp)
    return format(date, 'h:mm a')
  } catch (error) {
    console.error('error converting timestamp to 12hr format', error)
    return timestamp
  }
}

export const convert_12hr_format_to_timestamp = (timeString: string) => {
  try {
    const parsedTime = parse(timeString, 'h:mm a', new Date())
    return format(parsedTime, "yyyy-MM-dd'T'HH:mm:00")
  } catch (error) {
    console.error('error converting 12hr format to timestamp', error)
    return timeString
  }
}

export const getConsolidatedDaysOfWeek = (days_of_week: string[] | undefined) => {
  if (!days_of_week || days_of_week.length === 0) {
    return null
  }

  const isWeekdays = weekDays.every((day) => days_of_week.includes(day)) && days_of_week.length === 5

  const isAllDays = allDays.every((day) => days_of_week.includes(day))

  if (isAllDays) {
    return 'everyday'
  } else if (isWeekdays) {
    return 'weekdays'
  } else {
    return 'custom' // or you could return 'weekends' if that's a valid option
  }
}

export const hasManageOrganizationPermission = (member: OrganizationMember): boolean => {
  return (
    member?.roles.some((role) => role.permissions.includes(OrganizationPermissionTypes.MANAGE_ORGANIZATION)) ?? false
  )
}

export const checkPermissionToManageOrganization = (
  organizationId: string | undefined,
  organizationMembers?: OrganizationMember[]
): boolean => {
  if (!organizationId || !organizationMembers) {
    return false
  }

  const member = organizationMembers.find((member) => member.organization.id === organizationId)

  return hasManageOrganizationPermission(member as OrganizationMember)
}

const urlRegExp = new RegExp(
  /((([A-Za-z]{3,9}:(?:\/\/)?)(?:[-;:&=+$,\w]+@)?[A-Za-z0-9.-]+|(?:www.|[-;:&=+$,\w]+@)[A-Za-z0-9.-]+)((?:\/[+~%/.\w-_]*)?\??(?:[-+=&;%@.\w_]*)#?(?:[\w]*))?)/
)

export function validateUrl(url: string): boolean {
  return url === 'https://' || urlRegExp.test(url)
}

export function formatDateString(date: string, dateFormat?: string): string {
  if (!date) {
    return ''
  }
  const updatedDate = parseISO(date)
  return dateFormat ? format(updatedDate, dateFormat) : updatedDate.toDateString()
}

export function formatStringToFullMonthDayYear(date: string): string {
  return formatDateString(date, 'MMMM d, yyyy')
}

export function formatStringToShortMonthDayYear(date: string): string {
  return formatDateString(date, 'MMM d, yyyy')
}

export function UserFullName(user: UserProfile | UserContact): string {
  return `${user.first_name} ${user.last_name}`.trim()
}

export function convertISOStringToZeroTimeDate(dateStr: any) {
  const [year, month, day] = dateStr.split('-').map(Number)
  const date = new Date(year, month - 1, day) // Create local date (month is zero indexed)
  date.setHours(0, 0, 0, 0) // Set local time to 00:00:00
  return date
}

export function convertToZeroTimeISOString(dateStr: any) {
  const date = new Date(dateStr)
  date.setUTCHours(0, 0, 0, 0) // Set time to 00:00:00 UTC
  return date.toISOString()
}

export const getPrimaryContactPhone = (phoneData: any): string => {
  if (!phoneData || !Array.isArray(phoneData) || phoneData.length === 0) {
    return ''
  }

  const cleansedPhoneData = phoneData.filter((phone: any) => phone.number && !phone?.deleted_at)

  if (cleansedPhoneData.length === 0) {
    return ''
  }

  // if primary contact is available, use it as default before work contact or first contact
  const primaryContact = cleansedPhoneData.find((phone: any) => phone.primary)
  const workContact = cleansedPhoneData.find((phone: any) => phone.contact_type === ContactPhoneTypes.WORK)
  const firstContact = cleansedPhoneData[0]
  return primaryContact ? primaryContact.number : workContact ? workContact.number : firstContact.number
}

export const getPrimaryContactEmail = (emailData: any): string => {
  if (!emailData || !Array.isArray(emailData) || emailData.length === 0) {
    return ''
  }

  const cleansedEmailData = emailData.filter((email: any) => email.address && !email?.deleted_at)

  if (cleansedEmailData.length === 0) {
    return ''
  }

  // if primary contact is available, use it as default before work contact or first contact
  const primaryContact = cleansedEmailData.find((email: any) => email.primary)
  const workContact = cleansedEmailData.find((email: any) => email.contact_type === ContactEmailTypes.WORK)
  const firstContact = cleansedEmailData[0]
  return primaryContact ? primaryContact.address : workContact ? workContact.address : firstContact.address
}

export const getOrganizationCardDetails = (organization: Organization): OrganizationCardDetails => {
  const defaultData: OrganizationCardDetails = {
    small_logo: organization?.small_logo,
    name: organization?.name,
    address: {
      street: '',
      city: '',
      state: '',
      zip: '',
    },
    phone: '',
    email: '',
    verified: organization?.verified || false,
  }

  // Use locations to populate address, phone, and email
  // location with primary is preferred as default
  // work phone and work email are preferred as default
  if (organization?.locations && organization?.locations.length > 0) {
    const primaryLocation =
      organization?.locations.find((loc: any) => loc.primary_location) || organization?.locations[0]

    if (primaryLocation) {
      // Address
      if (primaryLocation.address && primaryLocation.address.address) {
        const { street, city, state, zip } = primaryLocation.address.address
        defaultData.address = {
          street: street || '',
          city: city || '',
          state: state || '',
          zip: zip || '',
        }
      }

      // Phone
      defaultData.phone = getPrimaryContactPhone(primaryLocation.phone)

      // Email
      defaultData.email = getPrimaryContactEmail(primaryLocation.email)
    }
  }
  return defaultData
}

export function excludeDeletedItems(items: any[]): any[] {
  if (!items) return []
  return items.filter((item) => item.deleted_at === null)
}
