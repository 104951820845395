import Box from '@mui/material/Box'
import { useUserInfo } from '@/contexts/hooks/useUserInfo.ts'
import Typography from '@mui/material/Typography'
import { PencilIcon } from 'lucide-react'
import Button from '@mui/material/Button'
import { useState } from 'react'
import Grid from '@mui/material/Grid'
import Avatar from '@mui/material/Avatar'
import { isPlainArray } from '@tanstack/react-router'
import MyProfileForm from '@/components/account-preferences/my-profile-form.tsx'
import { ContactAddress, ContactEmail, ContactPhone, GCSFile, ImageFile, User, UserProfile } from '@/types.ts'
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query'
import { updateUser } from '@/api/user.ts'
import { convertUserToUserProfile, trimValues } from '@/lib/utils.ts'
import { UserProfileSchema } from '@/lib/validation-schemas.ts'
import { z } from 'zod'
import { uploadFile } from '@/api/gcs.ts'
import { fetchGCSFile } from '@/lib/gcs-file-fetcher.ts'
import Divider from '@mui/material/Divider'

const MyProfileTab = () => {
  const queryClient = useQueryClient()
  const { userInfo } = useUserInfo()
  const [userProfile, setUserProfile] = useState<UserProfile>(userInfo?.user as UserProfile)
  const [profilePicture, setProfilePicture] = useState<File | null>(null)
  const [validationError, setValidationError] = useState<string | null>(null)
  const [isEditingMyProfile, setIsEditingMyProfile] = useState(false)

  const refetchUserProfileData = async () => {
    if (userInfo) {
      await queryClient.invalidateQueries({
        queryKey: ['user-info-organizations'],
      })
      await queryClient.invalidateQueries({
        queryKey: ['account-profile-picture'],
      })
    }
  }

  const { data: profilePictureImage, refetch: refetchProfilePicture } = useQuery({
    queryKey: ['account-profile-picture'],
    queryFn: async () => {
      if (!userProfile?.profile_picture) {
        return null
      }

      return (await fetchGCSFile(userProfile.profile_picture as GCSFile)) as ImageFile
    },
  })

  const uploadProfilePictureMutation = useMutation({
    mutationFn: async ({ put_url, file }: { put_url: string; file: File }) => {
      const response = await uploadFile({
        file,
        contentType: file.type,
        putUrl: put_url,
      })
      return response
    },
  })

  const updateUserMutation = useMutation({
    mutationFn: updateUser,
  })

  const cleanseUserProfileData = (profileData: UserProfile) => {
    const requestData = trimValues(profileData)
    // remove empty phone numbers
    requestData.phone = requestData.phone.filter((phone: ContactPhone) => phone.number)
    // remove empty email addresses
    requestData.other_email = requestData.other_email.filter((email: ContactEmail) => email.address)
    // remove empty addresses addresses
    requestData.address = requestData.address.filter((address: ContactAddress) => address.address)
    return requestData
  }

  const validateForm = (requestData: UserProfile): boolean => {
    setValidationError(null)
    try {
      const schema = UserProfileSchema
      schema.parse(requestData)
      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        setValidationError(error.errors[0].message)
      } else {
        setValidationError('An unknown error occurred')
      }
      return false
    }
  }

  const submitMyProfile = async (profileData: UserProfile) => {
    setValidationError(null)
    const requestData = cleanseUserProfileData(profileData)
    requestData.email = userInfo?.user.email
    requestData.id = userInfo?.user.id

    if (validateForm(requestData)) {
      try {
        if (profilePicture) {
          requestData.profile_picture = {
            user_file_name: profilePicture.name,
            file_type: profilePicture.type,
            encoding: profilePicture.type,
            domain: 'profile_picture',
          } as Partial<GCSFile>

          const updatedUserData = await updateUserMutation.mutateAsync(requestData)

          await uploadProfilePictureMutation.mutateAsync({
            put_url: updatedUserData.profile_picture?.put_url as string,
            file: profilePicture,
          })

          setUserProfile(updatedUserData)
        } else {
          const updatedUserData = await updateUserMutation.mutateAsync(requestData)
          setUserProfile(updatedUserData)
        }

        await refetchProfilePicture()
        await refetchUserProfileData()
        setIsEditingMyProfile(false)
      } catch (error) {
        setValidationError(`Failed to save profile. Please try again. ${error}`)
      }
    }
  }

  const handleEditingMyProfile = () => {
    setValidationError(null)
    if (isEditingMyProfile) {
      setIsEditingMyProfile(false)
    } else {
      setUserProfile(convertUserToUserProfile(userInfo?.user as User))
      setIsEditingMyProfile(true)
    }
  }

  return (
    <>
      {/** Tab Header */}
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center',
          pb: 4,
        }}
      >
        <Typography variant="tabHeader" sx={{ fontWeight: 700 }}>
          My Profile
        </Typography>
        {!isEditingMyProfile && (
          <Button
            variant="contained"
            color="inherit"
            onClick={() => handleEditingMyProfile()}
            sx={{
              minWidth: '40px',
              width: '40px',
              height: '40px',
              padding: 0,
              boxShadow: 0,
              border: 1,
              borderColor: '#E0E0E0',
              backgroundColor: 'white',
              ':hover': {
                boxShadow: 0,
              },
            }}
          >
            <PencilIcon size={20} />
          </Button>
        )}
      </Box>

      <Divider sx={{ mb: 4 }} />

      {isEditingMyProfile ? (
        <div>
          {/** Tab Form */}
          <MyProfileForm
            user={userProfile}
            onSubmit={submitMyProfile}
            onCancel={handleEditingMyProfile}
            validationError={validationError}
            currentProfilePicture={profilePictureImage?.url as string}
            setProfilePicture={setProfilePicture}
          />
        </div>
      ) : (
        <Grid container spacing={2}>
          {/** Tab Content */}
          {/* Left side: User information */}
          <Grid item xs={12} md={8}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                mb: 4,
                gap: 1,
              }}
            >
              <Typography variant="tabSectionXl" component="h1" sx={{ fontWeight: 700 }}>
                {userInfo?.name || 'n/a'}
              </Typography>
              <Typography variant="body1" sx={{ fontStyle: 'italic' }} gutterBottom>
                {userInfo?.user?.title || 'n/a'}
              </Typography>
              <Typography variant="body1" paragraph>
                {userInfo?.user?.biography || 'n/a'}
              </Typography>
            </Box>

            {/* Contact Information */}
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                mb: 2,
              }}
            >
              <Typography variant="tabSection">Phone</Typography>
              {userInfo?.user?.phone.length && isPlainArray(userInfo?.user.phone) ? (
                userInfo.user.phone.map((phone, index) => (
                  <Typography key={`phone-${index}`} variant="body1" gutterBottom>
                    {phone.number}
                  </Typography>
                ))
              ) : (
                <Typography variant="body1" gutterBottom>
                  n/a
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
                mb: 4,
              }}
            >
              <Typography variant="tabSection">Email</Typography>
              {userInfo?.user?.other_email.length && isPlainArray(userInfo?.user?.other_email) ? (
                userInfo?.user?.other_email.map((email, index) => (
                  <Typography key={`email-${index}`} variant="body1" gutterBottom>
                    {email.address}
                  </Typography>
                ))
              ) : (
                <Typography variant="body1" gutterBottom>
                  n/a
                </Typography>
              )}
            </Box>

            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'start',
              }}
            >
              <Typography variant="tabSection" sx={{ fontWeight: 700, pb: 1 }}>
                Address
              </Typography>
              {userInfo?.user?.address.length && isPlainArray(userInfo?.user.address) ? (
                userInfo?.user?.address.map((address, index) => (
                  <Box key={`address-${index}`}>
                    <Typography key={`address-line1-${index}`} variant="body1" gutterBottom>
                      {address.address.street || 'n/a'}
                    </Typography>
                    <Typography key={`address-line2-${index}`} variant="body1" gutterBottom>
                      {[address?.address?.city, address?.address?.state, address?.address?.zip]
                        .filter(Boolean)
                        .join(', ')}
                    </Typography>
                  </Box>
                ))
              ) : (
                <Typography variant="body1" gutterBottom>
                  n/a
                </Typography>
              )}
            </Box>
          </Grid>

          {/* Right side: Profile Picture and actions */}
          <Grid item xs={12} md={4}>
            <Box
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'end',
              }}
            >
              <Avatar
                alt="PB"
                src={profilePictureImage?.url || ''}
                sx={{
                  width: 120,
                  height: 120,
                  mb: 2,
                  border: 1,
                  backgroundColor: '#F0F0F0',
                  borderRadius: 1,
                }}
              />
            </Box>
          </Grid>
        </Grid>
      )}
    </>
  )
}

export default MyProfileTab
