import {
  closestCenter,
  DndContext,
  DragEndEvent,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
} from '@dnd-kit/core'
import { arrayMove, SortableContext, sortableKeyboardCoordinates, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Fragment, useMemo, type ChangeEvent, useState } from 'react'

import Checkbox from '@mui/material/Checkbox'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Typography from '@mui/material/Typography'

import RfpPlantTableItem from '@/components/rfps/rfp-plant-table-item.tsx'
import type { PlantListEntry } from '@/types.ts'
import { useQuery } from '@tanstack/react-query'
import { getOptions } from '@/api/plant-list.ts'
import EditPlantListEntryModal from '@/components/ui/modals/edit-plant-list-entry-modal.tsx'

interface RfpPlantTableProps {
  plants: PlantListEntry[]
  onUpdate: (updatedPlants: PlantListEntry[]) => void
  onSelectionChange?: (selectedPlantIds: Set<string>) => void
  editMode?: boolean
}
export default function RfpPlantTable({ plants, onUpdate, onSelectionChange, editMode = false }: RfpPlantTableProps) {
  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())
  const [editModalOpen, setEditModalOpen] = useState(false)
  const [editPlant, setEditPlant] = useState<PlantListEntry | null>(null)
  const activePlants = useMemo(() => plants.filter((plant) => !plant.deleted_at), [plants])
  const { data: optionsEnums } = useQuery({
    queryKey: ['optionEnums'],
    queryFn: getOptions,
  })

  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates,
    })
  )
  const handleDragEnd = (event: DragEndEvent) => {
    const { active, over } = event

    if (!over || active.id === over.id) {
      return
    }

    const oldIndex = plants.findIndex((plant) => plant.id === active.id)
    const newIndex = plants.findIndex((plant) => plant.id === over.id)

    const newPlants = arrayMove(plants, oldIndex, newIndex)

    // Update parent_of_order references
    const updatedPlants = newPlants.map((plant, index) => {
      if (index === newPlants.length - 1) {
        return { ...plant, parent_of_order: null }
      }
      return { ...plant, parent_of_order: newPlants[index + 1].id }
    })

    // Notify parent of the update
    onUpdate(updatedPlants)
  }

  const toggleSelect = (plantId: string) => {
    setSelectedPlants((prev) => {
      const newSelected = new Set(prev)
      if (newSelected.has(plantId)) {
        newSelected.delete(plantId)
      } else {
        newSelected.add(plantId)
      }
      onSelectionChange?.(newSelected)
      return newSelected
    })
  }

  const handlePlantUpdate = (updatedPlant: PlantListEntry) => {
    const updatedPlants = plants.map((plant) => (plant.id === updatedPlant.id ? updatedPlant : plant))
    onUpdate(updatedPlants)
  }

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    const newSelected = event.target.checked ? new Set(activePlants.map((plant) => plant.id)) : new Set<string>()
    setSelectedPlants(newSelected)
    onSelectionChange?.(newSelected)
  }

  const handleEditEntry = (entry: PlantListEntry) => {
    setEditPlant(entry)
    setEditModalOpen(true)
  }

  return (
    <>
      <DndContext sensors={sensors} collisionDetection={closestCenter} onDragEnd={handleDragEnd}>
        <Table stickyHeader>
          <TableHead sx={{}}>
            <TableRow>
              {editMode && <TableCell padding="none" sx={{}} />}
              <TableCell padding="checkbox">
                <Checkbox
                  checked={activePlants.length > 0 && activePlants.every((p) => selectedPlants.has(p.id))}
                  indeterminate={
                    activePlants.some((p) => selectedPlants.has(p.id)) &&
                    !activePlants.every((p) => selectedPlants.has(p.id))
                  }
                  onChange={handleSelectAll}
                />
              </TableCell>
              <TableCell>
                <Typography>Plant Name</Typography>
              </TableCell>
              <TableCell>
                <Typography>Specs</Typography>
              </TableCell>
              <TableCell>
                <Typography>QTY Needed</Typography>
              </TableCell>
              <TableCell padding="none" sx={{}} />
            </TableRow>
          </TableHead>
          <TableBody>
            <SortableContext items={activePlants.map((plant) => plant.id)} strategy={verticalListSortingStrategy}>
              {activePlants.map((plant) => (
                <Fragment key={plant.id}>
                  <RfpPlantTableItem
                    plant={plant}
                    onUpdate={handlePlantUpdate}
                    editMode={editMode}
                    isSelected={selectedPlants.has(plant.id)}
                    onSelect={toggleSelect}
                    optionsEnums={optionsEnums}
                    onEditEntry={handleEditEntry}
                  />
                </Fragment>
              ))}
            </SortableContext>
          </TableBody>
        </Table>
      </DndContext>
      {editPlant && (
        <EditPlantListEntryModal
          open={editModalOpen}
          onClose={() => setEditModalOpen(false)}
          entry={editPlant}
          plantListEntries={activePlants}
          onUpdate={handlePlantUpdate}
          onSwitchToPlant={(entry) => {
            setEditPlant(entry)
          }}
          optionsEnums={optionsEnums}
        />
      )}
    </>
  )
}
