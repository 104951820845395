import { ContactDetails, ContactEmail, CreateOrganizationLocationRequestBody, UpdateAccountPassword } from './types.ts'
import { ContactAddressTypes, ContactEmailTypes, ContactPhoneTypes } from './constants.ts'

export const initialFormEmail: ContactEmail = {
  id: null,
  address: '',
  contact_type: ContactEmailTypes.WORK,
  primary: false,
}

export const initialFormPhone = {
  id: null,
  number: '',
  extension: '',
  contact_type: ContactPhoneTypes.WORK,
  primary: false,
}

export const initialFormBaseAddress = {
  id: null,
  street: '',
  city: '',
  state: '',
  zip: '',
  country: 'USA',
}

export const initialFormAddress = {
  id: null,
  address_type: ContactAddressTypes.HEADQUARTERS,
  primary: false,
  address: initialFormBaseAddress,
}

export const initialOrgContactDetails: ContactDetails = {
  type: 'organization',
  organization_name: '',
  address: [],
  phone: [],
  email: [],
}

export const initialPersonContactDetails: ContactDetails = {
  type: 'user',
  organization_contact: [],
  organization_name: '',
  first_name: '',
  last_name: '',
  phone: [],
  email: [],
}

export const initialFormLocation: CreateOrganizationLocationRequestBody = {
  id: null,
  address: initialFormAddress,
  phone: [initialFormPhone],
  email: [initialFormEmail],
  organization: '',
  primary_location: false,
  name: '',
}

export const initialFormUpdateAccountPassword: UpdateAccountPassword = {
  current_password: '',
  new_password: '',
  confirm_password: '',
}
