import { PlantListEntry } from '@/types.ts'
import { useSortable } from '@dnd-kit/sortable'
import { CSS } from '@dnd-kit/utilities'
import TableRow from '@mui/material/TableRow'
import TableCell from '@mui/material/TableCell'
import Box from '@mui/material/Box'
import DragIndicatorIcon from '@mui/icons-material/DragIndicator'
import Checkbox from '@mui/material/Checkbox'
// import PlantItemSpecs from '@/components/plant-list-importer/plant-item-specs.tsx'
import Typography from '@mui/material/Typography'
import { EllipsisVertical, Trash2 } from 'lucide-react'
import { TextField } from '@mui/material'
import React, { useState } from 'react'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import PlantItemSpecs from '@/components/plant-list-importer/plant-item-specs.tsx'

interface SortablePlantTableItemProps {
  plant: PlantListEntry
  onUpdate: (updatedPlant: PlantListEntry) => void
  editMode?: boolean
  isSelected: boolean
  onSelect: (id: string) => void
  optionsEnums: Record<string, any> | undefined
  onEditEntry: (entry: PlantListEntry) => void
}

export default function RfpPlantTableItem({
  plant,
  onUpdate,
  editMode = false,
  isSelected,
  onSelect,
  optionsEnums,
  onEditEntry,
}: SortablePlantTableItemProps) {
  const { attributes, listeners, setNodeRef, transform, transition, isDragging } = useSortable({ id: plant.id })
  const style = {
    transform: CSS.Translate.toString(transform),
    transition,
    opacity: isDragging ? 0.5 : 1,
  }
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  const handleCheckboxClick = (id: string) => {
    onSelect(id)
  }

  const handleQuantityChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newValue = parseInt(event.target.value) || 1
    onUpdate({ ...plant, quantity_count: { min: newValue, max: null } })
  }

  const handleMenuOpen = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleMenuClose = () => {
    setAnchorEl(null)
  }

  const handleEditPlantDetails = (entry: PlantListEntry) => {
    onEditEntry(entry)
    handleMenuClose()
  }

  const handleRemoveFromRFP = () => {
    onUpdate({ ...plant, deleted_at: new Date().toISOString() })
    handleMenuClose()
  }

  const handleSpecUpdate = (updatedPlant: PlantListEntry) => {
    onUpdate(updatedPlant)
  }

  return (
    <TableRow ref={setNodeRef} style={style} hover id={plant.id}>
      {editMode && (
        <TableCell padding="none" style={{ width: '48px' }}>
          <Box
            {...attributes}
            {...listeners}
            sx={{
              display: 'flex',
              h: '100%',
              cursor: 'move',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <DragIndicatorIcon />
          </Box>
        </TableCell>
      )}
      <TableCell padding="checkbox" onClick={() => handleCheckboxClick(plant.id)}>
        <Checkbox checked={isSelected} />
      </TableCell>
      <TableCell>
        <Typography variant="body1" textTransform="capitalize">
          {plant.common_name || plant.scientific_name}
        </Typography>
      </TableCell>
      <TableCell>
        {/*<PlantItemSpecs entry={plant} />  - when implementing this factor editMode to render states */}
        <PlantItemSpecs entry={plant} onUpdate={handleSpecUpdate} optionsEnums={optionsEnums} editMode={editMode} />
      </TableCell>
      <TableCell>
        {editMode ? (
          <TextField
            type="number"
            value={plant.quantity_count.min}
            onChange={handleQuantityChange}
            variant="outlined"
            size="small"
            inputProps={{
              min: 1,
            }}
            sx={{
              width: '100px',
            }}
          />
        ) : (
          <Typography variant="body1" sx={{ textAlign: 'center' }}>
            {plant.quantity_count.min}
          </Typography>
        )}
      </TableCell>
      <TableCell padding="none" style={{ width: '48px' }}>
        {editMode && (
          <Box
            sx={{
              display: 'flex',
              h: '100%',
              cursor: 'pointer',
              alignItems: 'center',
              justifyContent: 'center',
            }}
            onClick={handleMenuOpen}
          >
            <EllipsisVertical />
          </Box>
        )}
        <Menu
          anchorEl={anchorEl}
          open={open}
          onClose={handleMenuClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleEditPlantDetails(plant)}>
            <Typography variant="body1">Edit Details</Typography>
          </MenuItem>
          <MenuItem>
            <Typography variant="body1">Flag Item as Important</Typography>
          </MenuItem>
          <Divider />
          <MenuItem onClick={handleRemoveFromRFP}>
            <Trash2 style={{ marginRight: '8px' }} />
            <Typography variant="body1">Remove from RFP</Typography>
          </MenuItem>
        </Menu>
      </TableCell>
    </TableRow>
  )
}
