import { ReactNode } from 'react'

/**
 * Entity Types: User, Organization, File
 */
export type Organization = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  name: string
  website?: string
  created_by: User | null
  logo: GCSFile | null
  small_logo: GCSFile | null
  primary_business_type?: 'buyer' | 'seller' | 'designer' | 'hauler' | null
  verified: boolean
  legal_name: string | null
  company_structure: 'individual' | 'llc' | 's corp' | 'c corp' | null
  tax_id: string | null
  members?: OrganizationMember[]
  license?: OrganizationLicense[]
  locations?: OrganizationLocation[]
}

export type OrganizationMember = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  organization: Organization
  roles: Role[]
  invite_status: string
  user: User
  notification_settings?: OrganizationMemberNotificationSettings
}
export type MinimalOrganizationMemberForNotificationSettings = {
  id: string
  organization: string
  roles: string[]
  notification_settings: OrganizationMemberNotificationSettings
}

export type OrganizationLicense = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  organization: Organization
  file: GCSFile
  created_by: User
}

export type OrganizationLocation = {
  id: string | null
  organization: Organization
  name: string
  address: ContactAddress
  phone: ContactPhone[]
  email: ContactEmail[]
  primary_location: boolean
  created_at: string
  updated_at: string | null
  deleted_at: string | null
}

type Role = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  name: string
  permissions: string[]
  custom_role: boolean
}

export type Auth0UserProfileDataMinimal = {
  name: string
  given_name: string
  family_name: string
  email: string
  email_verified: boolean
  picture: string
}
export type Auth0UserIdentity = {
  profileData: Auth0UserProfileDataMinimal
  user_id: string
  isSocial: string
  provider: string
  connection?: string
  primary?: boolean
}
export type Auth0UserExpanded = {
  email?: string
  email_verified?: boolean
  family_name?: string
  given_name?: string
  identities?: Auth0UserIdentity[]
  idp_tenant_domain?: string
  last_ip?: string
  last_login?: string
  logins_count?: number
  name?: string
  nickname?: string
  picture?: string
  updated_at?: string
  user_id?: string
}

export type UserDontShowSettings = {
  plantlist_detail_review?: string | null
}

export type User = {
  id: string
  first_name: string
  last_name: string
  email: string
  organization_members: OrganizationMember[]
  pubsub_channel: string
  created_at: string
  title: string | null
  biography: string | null
  profile_picture?: Partial<GCSFile> | null
  address: ContactAddress[]
  phone: ContactPhone[]
  timezone: string | null
  other_email: ContactEmail[]
  updated_at: string | null
  deleted_at: string | null
  dont_show?: UserDontShowSettings
}

// GCS = Google Cloud Storage
export type GCSFile = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  user_file_name: string
  file_type: string
  encoding: string
  domain: string
  get_url: string
  put_url: string
}
export interface SpreadsheetFile extends GCSFile {
  tables: string[]
}

export interface ImageFile extends GCSFile {
  url: string
  alt: string
}

/* User and UserProfile */
export type UserProfile = {
  id: string
  first_name: string
  last_name: string
  email: string
  title: string | null
  biography: string | null
  address: ContactAddress[]
  phone: ContactPhone[]
  other_email: ContactEmail[]
  profile_picture?: Partial<GCSFile> | string | null
  timezone: string | null
}

export type UserInfo = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  sub: string | null
  nickname: string | null
  name: string | null
  // profile_picture?: Partial<GCSFile> | null
  email: string | null
  email_verified: boolean | null
  first_name: string | null
  last_name: string | null
  phone_number: string | null
  phone_number_verified: boolean | null
  user: User
}

/**
 * Request Body Types
 */
export type OrganizationRequestBody = {
  id?: string
  name?: string
  website?: string
  mailing_address?: OrganizationAddressRequest
  logo?: Partial<GCSFile> | null
  small_logo?: Partial<GCSFile> | null
  primary_business_type?: 'buyer' | 'seller' | 'designer' | 'hauler' | null
  verified?: boolean
  legal_name?: string | null
  company_structure?: 'individual' | 'llc' | 's corp' | 'c corp' | null
  tax_id?: string | null
}

type OrganizationAddressRequest = {
  street: string
  city: string
  state: string
  zip: string
  country: string
  id?: string | null
}

export type CreateOrganizationLocationRequestBody = {
  id?: string | null
  organization: string
  name: string
  address: ContactAddress
  phone: ContactPhone[]
  email: ContactEmail[]
  primary_location: boolean
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}

export type CreateOrganizationLicenseRequestBody = {
  organization: string
  file: Partial<GCSFile>
}

/**
 * Plant List Importer Types
 */
export type RangeDimension = {
  min: number | null
  max: number | null
}
export interface MeasurableRangeDimension extends RangeDimension {
  unit: string | null
}

export type V1Match = {
  id: number
  display_name: string
  common_names: string[]
  scientific_name: string
  genus: string
  species: string
}

export enum TrunkPlurality {
  SingleTrunk = 'Single Trunk',
  MultiTrunk = 'Multi Trunk',
}

interface TrunkForm {
  plurality: TrunkPlurality
  trunk_count: RangeDimension
  clear_trunk_size: MeasurableRangeDimension
}

export type SingleTrunk = TrunkForm & {
  plurality: TrunkPlurality.SingleTrunk
  trunk_count: RangeDimension & { min: 1; max: 1 }
}

export type MultiTrunk = TrunkForm & {
  plurality: TrunkPlurality.MultiTrunk
  cane_caliper: MeasurableRangeDimension
  trunk_count: RangeDimension
}

export type PlantListEntry = {
  id: string
  caliper: MeasurableRangeDimension
  dbh: MeasurableRangeDimension
  container: MeasurableRangeDimension
  height: MeasurableRangeDimension
  width: MeasurableRangeDimension
  surface_area: MeasurableRangeDimension
  package_count: RangeDimension
  quantity_count: RangeDimension
  shipping_container: string | null
  plant_container: string | null
  root_packaging: string | null
  trunk_form: SingleTrunk | MultiTrunk | null
  file_id: string | null
  scientific_name: string | null
  common_name: string | null
  notes: string | null
  plant_id: string | null
  shape: string[]
  palm_trunk: string[]
  plant_stage: string[]
  characteristics: string[]
  rfp_id: number[]
  parent_of_order: string | null
  deleted_at: string | null
  is_new?: boolean
  job_id?: string | null
}

export type PlantList = {
  id: string
  name: string
  files: GCSFile[]
  created_at: string
  updated_at: string
  deleted_at?: string | null
  entries: PlantListEntry[]
  entries_count?: number
  organization: Organization
  rfp_ids: { id: number; url: string }[]
  created_by: User
  verified?: boolean
}

/**
 * AI Processing Types
 */
type ProcessingError = {
  error_type: string
  message: string
  retry_iteration: number
}

export type AIProcessingData = {
  _id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  file_id: string
  user_file_name: string
  parent_plant_list_id: string
  processing_errors: ProcessingError[]
  breaking_error: ProcessingError | null
  processing_began: string | null
  processing_completed: string | null
  pubsub_channel: string
}

/**
 * Contact Types
 */

export type ContactType = 'organization' | 'user'

export type BaseAddress = {
  id: string | null
  street: string
  city: string
  state: string
  zip: string
  country: string
  suite?: string | null
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}
export type ContactAddress = {
  id: string | null
  address_type?: 'headquarters' | 'mailing' | 'other'
  primary: boolean
  address: BaseAddress
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}
export type ContactPhone = {
  id: string | null
  number: string
  extension: string
  contact_type: 'work' | 'mobile' | 'other'
  primary: boolean
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}
export type ContactEmail = {
  id: string | null
  address: string
  contact_type: 'work' | 'other'
  primary: boolean
  created_at?: string | null
  updated_at?: string | null
  deleted_at?: string | null
}

export type MinimalOrgContactDetails = {
  id?: string | null
  organization_name: string
  organization: Organization | null | undefined
  type: 'organization'
}

export type ContactDetails = {
  organization_contact?: MinimalOrgContactDetails[]
  organization_name?: string
  first_name?: string
  last_name?: string
  address?: ContactAddress[]
  phone?: ContactPhone[]
  email?: ContactEmail[]
  type?: ContactType
  id?: string
}
type ContactCount = {
  total: number
  people: number
  organizations: number
}
export type ContactCountCollection = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  favorite: ContactCount
  all: ContactCount
  customers: ContactCount
  vendors: ContactCount
  my_team: ContactCount
}

type AddressResponse = {
  address: {
    street: string
    city: string
    state: string
    zip: string
    country: string
  }
}

export type OrganizationContact = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  type: 'organization'
  created_by: User
  user: User | null
  owner: Organization
  organization: Organization | null
  contact_organization: Organization | null
  organization_name: string
  address?: AddressResponse[]
  phone?: ContactPhone[]
  email?: ContactEmail[]
  favorite: boolean
}

export type UserContact = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  type: 'user'
  created_by: User
  user: User
  owner: Organization
  organization_contact: OrganizationContact[]
  first_name: string
  last_name: string
  address?: AddressResponse[]
  phone?: ContactPhone[]
  email?: ContactEmail[]
  favorite: boolean
}

export type Contact = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  type: ContactType
  created_by: User
  organization: Organization
  organization_contact: OrganizationContact[]
  organization_name?: string
  first_name?: string
  last_name?: string
  email?: ContactEmail[]
  phone?: ContactPhone[]
  address?: ContactAddress[]
  favorite: boolean
}
export type ContactDetailsResponse = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  owner: Organization
  contact: Contact
  organization_contact?: OrganizationContact[]
}

export type ElasticsearchResponse<T> = {
  page: number
  limit: number
  last_page: number
  results_count: number
  total: number
  results: T[]
  items: T[]
}

export type UpdateAccountPassword = {
  current_password: string
  new_password: string
  confirm_password: string
}

// plan to deprecate this type
export type SnackbarMessage = {
  message: string
  severity: 'success' | 'info' | 'warning' | 'error'
}

export type ToastNotificationMessage = {
  severity: 'success' | 'info' | 'warning' | 'error'
  message: string
  id?: string
  category?: string
  variant?: 'filled' | 'outlined'
  title?: string
}

export type NotificationSchedule = {
  start_time: string | null
  end_time: string | null
  days_of_week: string[]
}

export type OrganizationMemberNotificationSettings = {
  schedule: NotificationSchedule
  default_reminder_time: string | null
  channels: Array<'email' | 'text_message'>
  subscribed_categories: Array<'all' | 'rfp' | 'quote' | 'order' | 'job' | 'invoice' | 'delivery'>
}

export type Plant = {
  _id: string
  common_names: string[]
  display_name: string
  scientific_name: string
}

export type PlantWithQuantity = {
  plant: Plant
  quantity: number
  job_id?: string
}

export type DetailsSectionField = {
  label: string
  value?: string
  node?: ReactNode
}

export type OrganizationCardDetails = {
  small_logo: GCSFile | null
  name: string
  address: Partial<BaseAddress>
  phone: string
  email: string
  verified: boolean
}

export type Job = {
  id: string
  created_at: string
  updated_at: string | null
  deleted_at: string | null
  name: string
  organization: Organization
  created_by: User
  customer_job_id: string | null
  plants: PlantListEntry[]
  job_date: string | null
  delivery_date: string | null
  stage: string
}

//------------ interfaces

export interface DetailsSectionProps {
  title: DetailsSectionField
  fields: DetailsSectionField[]
  defaultNode: ReactNode | null
}

export interface OrganizationDetailsProps {
  organization: Organization | null | undefined
  displayLogo?: boolean
}

export interface ConfirmationModalProps {
  open: boolean
  title: string
  message: string
  onConfirm: () => void
  onCancel: () => void
  danger?: boolean
  loading?: boolean
}
