import api from '@/api/api.ts'
import type { OrganizationMember } from '@/types.ts'
import { OrganizationMemberNotificationSettings } from '@/types.ts'

type OrganizationMemberNotificationSettingsProps = {
  id: string
  organization: string
  roles: string[]
  notification_settings: Partial<OrganizationMemberNotificationSettings>
}

export async function updateOrganizationMemberNotificationSettings(data: OrganizationMemberNotificationSettingsProps) {
  const response = await api.put(`/v1/core/organization_members/${data.id}`, data, {
    headers: {
      'Content-Type': 'application/json',
    },
  })
  return response.data as OrganizationMember
}
