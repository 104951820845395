import { createFileRoute } from '@tanstack/react-router'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import { MountainSnow } from 'lucide-react'
import ControlPanel from '@/components/ui/base/control-panel'
import { useState } from 'react'
import { ElasticsearchResponse, PlantListEntry } from '@/types'
import { useQuery } from '@tanstack/react-query'
import { useOrganization } from '@/contexts/hooks/useOrganization.ts'
import { getJobsByOrganization } from '@/api/jobs.ts'
import { Stack } from '@mui/material'
import Typography from '@mui/material/Typography'

export const Route = createFileRoute('/_authenticated/jobs/')({
  component: JobsIndex,
})

// Example Job type
type Job = {
  id: string
  name: string
}

function JobsIndex() {
  const [activeTab, setActiveTab] = useState('my-jobs')
  const [_searchResults, setSearchResults] = useState<ElasticsearchResponse<Job> | null>(null)
  const { selectedOrganization } = useOrganization()
  const { data: jobsData } = useQuery({
    queryKey: ['jobs', selectedOrganization?.id],
    queryFn: async () => {
      return getJobsByOrganization(selectedOrganization?.id)
    },
    enabled: !!selectedOrganization,
  })

  return (
    <PageLayout title="Jobs" icon={<MountainSnow size={36} />} globalNewButton>
      <ControlPanel
        tabConfig={{
          tabs: [
            { label: 'My Jobs', value: 'my-jobs' },
            { label: 'Customer Jobs', value: 'customer-jobs' },
            { label: 'Plant List', value: 'by-plant' },
          ],
          activeTab: activeTab,
          onTabChange: setActiveTab,
        }}
        searchConfig={{
          placeholder: 'Search Jobs...',
          searchUrl: '/v1/core/job/search/',
          onSearchResults: (response) => setSearchResults(response as ElasticsearchResponse<any>),
        }}
      />
      {jobsData && (
        <Stack spacing={3} sx={{ width: 800 }}>
          {jobsData.map((job: { id: string; name: string; job_date: string; plants: PlantListEntry[] }) => (
            <Stack
              direction="row"
              key={job.id}
              sx={{
                justifyContent: 'space-between',
                alignItems: 'center',
              }}
            >
              <Typography sx={{ minWidth: 300, maxWidth: 300 }}>{job.name}</Typography>
              <Typography sx={{ minWidth: 200, textAlign: 'left' }}>{job.job_date}</Typography>
            </Stack>
          ))}
        </Stack>
      )}
    </PageLayout>
  )
}
