import { ElasticsearchResponse, Job, PlantList, PlantListEntry } from '@/types.ts'
import { ChangeEvent, Fragment, type SyntheticEvent, useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import { useQuery } from '@tanstack/react-query'
import { getJobsById, getJobsByOrganization } from '@/api/jobs.ts'
import { useOrganization } from '@/contexts/hooks/useOrganization'
import { Alert, Checkbox, CircularProgress, Divider, keyframes, Stack, Typography } from '@mui/material'
import TextField from '@mui/material/TextField'
import FormControlLabel from '@mui/material/FormControlLabel'
import SearchBar from '@/components/ui/base/search-bar.tsx'
import { format } from 'date-fns'
import Chip from '@mui/material/Chip'
import { ChevronRight } from 'lucide-react'

interface AddPlantsFromJobsModalProps {
  open: boolean
  onClose: () => void
  jobId: string
  onAddPlants: (plants: PlantListEntry[]) => Promise<void>
}

export default function AddPlantsFromJobsModal({ open, onClose, jobId, onAddPlants }: AddPlantsFromJobsModalProps) {
  const [selectedJobId, setSelectedJobId] = useState<string | null>(jobId)
  const [selectedPlants, setSelectedPlants] = useState<PlantListEntry[]>([])

  // sync the jobId with the selectedJobId
  useEffect(() => {
    setSelectedJobId(jobId)
  }, [jobId])

  const handleClose = () => {
    setSelectedPlants([])
    setSelectedJobId(null)
    onClose()
  }

  const handlePlantsSelected = (plants: PlantListEntry[]) => {
    setSelectedPlants(plants)
  }

  const handleAddPlants = async () => {
    await onAddPlants(selectedPlants)
    handleClose()
  }

  return (
    <Modal open={open} onClose={handleClose} closeAfterTransition aria-labelledby="add-plants-from-jobs-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 850,
          backgroundColor: 'background.paper',
          boxShadow: 24,
          p: 5,
          borderRadius: theme.borderRadius.md,
        }}
      >
        <ModalHeader
          title="Select Plants for RFP"
          sx={{ mb: 5, fontWeight: 400 }}
          id="add-plants-from-jobs-modal-title"
        />

        {selectedJobId ? (
          <SelectJobPlants jobId={selectedJobId} onPlantsSelected={handlePlantsSelected} />
        ) : (
          <SelectJobs onJobSelected={setSelectedJobId} />
        )}

        <Box
          sx={{
            display: 'flex',
            justifyContent: 'flex-end',
            gap: 2,
            mt: 5,
          }}
        >
          <PrimaryCancelButton onClick={handleClose}>Cancel &amp; Close</PrimaryCancelButton>
          <DarkPrimaryButton onClick={handleAddPlants} disabled={!selectedPlants.length || !selectedJobId}>
            Add Plants
          </DarkPrimaryButton>
        </Box>
      </Box>
    </Modal>
  )
}

interface SelectJobsProps {
  onJobSelected: (jobId: string) => void
}

function SelectJobs({ onJobSelected }: SelectJobsProps) {
  const { selectedOrganization } = useOrganization()
  const [hideFullyAssignedJobs, setHideFullyAssignedJobs] = useState(true)
  const [jobSearchResults, setJobSearchResults] = useState<ElasticsearchResponse<PlantList> | null>(null)

  const handleCheckboxChange = (_event: SyntheticEvent, checked: boolean) => {
    setHideFullyAssignedJobs(checked)
  }

  const { data: jobs } = useQuery({
    queryKey: ['jobs', selectedOrganization?.id],
    queryFn: () => {
      if (!selectedOrganization || !selectedOrganization.id) {
        throw new Error('No organization selected')
      }
      return getJobsByOrganization(selectedOrganization.id)
    },
    enabled: !!selectedOrganization && !jobSearchResults,
  })

  return (
    <>
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          gap: 2,
          width: '100%',
          mb: 5,
        }}
      >
        <Typography variant="strongMd">Choose Job</Typography>
        <Divider orientation="vertical" flexItem />
        <SearchBar
          placeholder="Search jobs..."
          searchUrl="/v1/core/job"
          perPage={5}
          page={1}
          onSearchResults={(response) => {
            setJobSearchResults(response as ElasticsearchResponse<PlantList>)
          }}
          requiredFields={['name']}
          sx={{ width: 275 }}
        />
        <Box sx={{ ml: 'auto' }}>
          <FormControlLabel
            label="Hide fully assigned jobs"
            control={<Checkbox checked={hideFullyAssignedJobs} />}
            onChange={handleCheckboxChange}
          />
        </Box>
      </Box>
      <Box>
        {(jobSearchResults?.items || jobs)?.map((job: Job, index: number) => (
          <Box
            key={job.id}
            sx={{
              cursor: 'pointer',
              '&:hover': { bgcolor: theme.palette.grey[100] },
            }}
          >
            {index === 0 && <Divider />}
            <Stack
              direction="row"
              onClick={() => onJobSelected(job.id)}
              sx={{
                justifyContent: 'space-between',
                py: 2,
                pl: 2,
                alignItems: 'center',
              }}
            >
              <Typography variant="strong" sx={{ minWidth: 300, maxWidth: 300 }}>
                {job.name}
              </Typography>
              <Typography variant="body1" sx={{ minWidth: 200, textAlign: 'left' }}>
                {job?.job_date ? format(job.job_date, 'MMMM d, yyyy') : 'N/A'}
              </Typography>
              <Stack direction="row" spacing={2.5} sx={{ alignItems: 'center' }}>
                <Chip label={job.stage} sx={{ fontWeight: 700 }} />
                <ChevronRight />
              </Stack>
            </Stack>
            <Divider />
          </Box>
        ))}
      </Box>
    </>
  )
}

interface SelectPlantsProps {
  jobId: string
  onPlantsSelected: (
    plants: (PlantListEntry & {
      quantity_count: { min: number; max: number | null }
    })[]
  ) => void
}

function SelectJobPlants({ jobId, onPlantsSelected }: SelectPlantsProps) {
  const [selectedPlants, setSelectedPlants] = useState<Set<string>>(new Set())
  const [quantities, setQuantities] = useState<Record<string, number>>({})
  const { selectedOrganization } = useOrganization()

  const { data, isLoading } = useQuery({
    queryKey: ['job', jobId],
    queryFn: () => {
      return getJobsById(selectedOrganization?.id, jobId)
    },
  })

  const pulse = keyframes`
      0% {
          opacity: 1;
      }
      50% {
          opacity: 0.5;
      }
      100% {
          opacity: 1;
      }
  `

  if (isLoading)
    return (
      <Stack
        direction="row"
        spacing={1}
        sx={{
          height: 494,
          alignItems: 'center',
          justifyContent: 'center',
          color: theme.palette.grey[500],
        }}
      >
        <CircularProgress size={20} color="inherit" />
        <Typography
          variant="strongMd"
          sx={{
            animation: `${pulse} 1.5s ease-in-out infinite`,
          }}
        >
          Loading...
        </Typography>
      </Stack>
    )

  if (!data) return <Alert severity="error">Failed to fetch job data</Alert>

  if (data?.deleted_at !== null) return <Alert severity="error">Job has been deleted</Alert>

  const activePlants = data.plants.filter((entry: PlantListEntry) => !entry.deleted_at)

  const handleSelectAll = (event: ChangeEvent<HTMLInputElement>) => {
    if (!activePlants) return

    const newSelected = new Set<string>()
    const newQuantities = { ...quantities }

    if (event.target.checked) {
      activePlants.forEach((entry: PlantListEntry) => {
        newSelected.add(entry.id)
        if (!newQuantities[entry.id]) {
          newQuantities[entry.id] = entry.quantity_count.min || 1
        }
      })
    }

    setSelectedPlants(newSelected)
    setQuantities(newQuantities)
    updateSelectedPlants(newSelected, newQuantities)
  }

  const handleCheckboxChange = (entry: PlantListEntry) => {
    if (!data?.plants) return

    setSelectedPlants((prev) => {
      const newSelected = new Set(prev)
      if (newSelected.has(entry.id)) {
        newSelected.delete(entry.id)
      } else {
        newSelected.add(entry.id)
        setQuantities((prev) => ({
          ...prev,
          [entry.id]: entry.quantity_count.min || 1,
        }))
      }
      updateSelectedPlants(newSelected, quantities)
      return newSelected
    })
  }

  const handleQuantityChange = (entry: PlantListEntry, value: string) => {
    const quantity = parseInt(value) || 0
    if (quantity < 0) return

    setQuantities((prev) => {
      const newQuantities = { ...prev, [entry.id]: quantity }
      updateSelectedPlants(selectedPlants, newQuantities)
      return newQuantities
    })
  }

  const updateSelectedPlants = (selected: Set<string>, quantities: Record<string, number>) => {
    if (!data?.plants) return

    const newSelected = activePlants
      .filter((e: PlantListEntry) => selected.has(e.id))
      .map((entry: PlantListEntry) => ({
        ...entry,
        job_id: jobId,
        quantity_count: {
          min: quantities[entry.id] || entry.quantity_count.min || 1,
          max: quantities[entry.id] || null,
        },
      }))

    onPlantsSelected(newSelected)
  }

  return (
    <>
      <Stack
        direction="row"
        spacing={2}
        sx={{
          alignItems: 'center',
          px: 2,
          py: 1,
          borderBottom: 1,
          borderColor: 'divider',
        }}
      >
        <FormControlLabel
          control={
            <Checkbox
              checked={activePlants.length > 0 && selectedPlants.size === activePlants.length}
              indeterminate={selectedPlants.size > 0 && selectedPlants.size < activePlants.length}
              onChange={handleSelectAll}
            />
          }
          label="Select All"
        />
      </Stack>

      <Box sx={{ height: 360, overflowY: 'auto' }}>
        {activePlants.map((entry: PlantListEntry, index: number) => (
          <Fragment key={entry.id}>
            {index === 0 && <Divider />}
            <Stack
              direction="row"
              sx={{
                justifyContent: 'space-between',
                py: 2,
                px: 2,
                alignItems: 'center',
              }}
            >
              <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
                <Checkbox checked={selectedPlants.has(entry.id)} onChange={() => handleCheckboxChange(entry)} />
                <Typography
                  variant="body1"
                  sx={{
                    textTransform: 'capitalize',
                  }}
                >
                  {entry.common_name || entry.scientific_name || 'Unnamed Plant'}
                </Typography>
              </Stack>
              <Stack direction="row" spacing={2.5} sx={{ alignItems: 'center' }}>
                <TextField
                  type="number"
                  size="small"
                  value={
                    selectedPlants.has(entry.id)
                      ? quantities[entry.id] || entry.quantity_count.min || 1
                      : entry.quantity_count.min || 1
                  }
                  onChange={(e) => handleQuantityChange(entry, e.target.value)}
                  disabled={!selectedPlants.has(entry.id)}
                  inputProps={{ min: 0, style: { width: '60px' } }}
                />
                <Typography>TODO: Add Spec Chips</Typography>
              </Stack>
            </Stack>
            <Divider />
          </Fragment>
        ))}
      </Box>
    </>
  )
}
