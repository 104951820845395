import { useEffect, useState } from 'react'
import Modal from '@mui/material/Modal'
import ModalHeader from '@/components/ui/base/modal-header.tsx'
import { Stack, Typography } from '@mui/material'
import RadioGroup from '@mui/material/RadioGroup'
import Radio from '@mui/material/Radio'
import FormControlLabel from '@mui/material/FormControlLabel'
import { TextField, MenuItem, Box } from '@mui/material'
import Grid from '@mui/material/Grid2'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFnsV3'
import { convertISOStringToZeroTimeDate, convertToZeroTimeISOString, UserFullName } from '@/lib/utils.ts'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'

interface DescriptiveRadioButtonProps {
  options: any[]
  value: string
  onChange: (event: any) => void
}

interface EditRfpDetailsModalProps {
  open: boolean
  onClose: () => void
  rfpData: any
  onSubmit: (formData: any | RFPDetailsFormData) => void
}

type RFPDetailsFormData = {
  owner: string
  collaborators: string[]
  quoteNeededBy: Date | null
  deliveryDate: Date | null
}

type OwnerOption = {
  id: string
  name: string
}

type RfpRadioButtonOption = {
  value: string
  label: string
  description: string
}

export default function EditRfpDetailsModal({ open, onClose, rfpData, onSubmit }: EditRfpDetailsModalProps) {
  const [validationError, setValidationError] = useState<string | null>(null)

  const handleClose = () => {
    setValidationError(null)
    onClose()
  }

  const rfpRadioButtonOptions: RfpRadioButtonOption[] = [
    {
      value: 'bidding',
      label: 'Bidding',
      description: 'Just collecting quotes',
    },
    { value: 'buying', label: 'Buying', description: 'Ready to buy plants' },
  ]
  const ownerOptions: OwnerOption[] = [
    {
      id: rfpData.owner.id,
      name: UserFullName(rfpData.owner),
    },
  ]
  const initialFormData: RFPDetailsFormData = {
    owner: rfpData.owner.id || '',
    collaborators: [],
    quoteNeededBy: rfpData.quote_needed_by ? convertISOStringToZeroTimeDate(rfpData.quote_needed_by) : null,
    deliveryDate: rfpData.delivery_date ? convertISOStringToZeroTimeDate(rfpData.delivery_date) : null,
  }
  const [formValues, setFormValues] = useState(initialFormData)
  const collaboratorsOptions: any[] = []
  const [selectedOption, setSelectedOption] = useState('bidding')
  const onChange = (event: any) => {
    setSelectedOption(event.target.value)
  }

  // Reset form values & radio button selection
  useEffect(() => {
    if (open) {
      setFormValues(initialFormData)
      setSelectedOption('bidding')
    }
  }, [open, rfpData])

  const DescriptiveRadioButton = ({ options, value, onChange }: DescriptiveRadioButtonProps) => {
    return (
      <RadioGroup
        value={value}
        onChange={onChange}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          gap: 1,
        }}
      >
        {options.map((option: RfpRadioButtonOption) => (
          <FormControlLabel
            key={option.value}
            value={option.value}
            control={<Radio />}
            label={
              <Stack>
                <Typography
                  variant="body1"
                  sx={{
                    fontSize: '15px',
                    minWidth: 160,
                    fontWeight: option.value === value ? 700 : 400,
                  }}
                >
                  {option.label}
                </Typography>
                <Typography
                  variant="body2"
                  color="textSecondary"
                  sx={{
                    fontSize: '14px',
                    fontWeight: option.value === value ? 700 : 400,
                  }}
                >
                  {option.description}
                </Typography>
              </Stack>
            }
            style={{ alignItems: 'flex-start' }}
          />
        ))}
      </RadioGroup>
    )
  }

  const validateFormSubmission = () => {
    if (!formValues.quoteNeededBy) {
      setValidationError('Quote Needed by date is required')
      return false
    }
    return true
  }

  const handleFormSubmission = () => {
    if (validateFormSubmission()) {
      const formValuesToSubmit = {
        quote_needed_by: formValues.quoteNeededBy ? convertToZeroTimeISOString(formValues.quoteNeededBy) : null,
        delivery_date: formValues.deliveryDate ? convertToZeroTimeISOString(formValues.deliveryDate) : null,
      }
      onSubmit(formValuesToSubmit)
      handleClose()
    }
  }

  const EditRfpDetailsForm = () => {
    const handleInputChange = (field: any, value: any) => {
      setFormValues((prev: any) => ({ ...prev, [field]: value }))
      setValidationError('')
    }

    const handleDateChange = (field: any, value: any) => {
      // const formattedDate = value ? format(new Date(value), 'yyyy-MM-dd') : null
      const formattedDate = value ? new Date(value) : null
      setFormValues((prev: any) => ({ ...prev, [field]: formattedDate }))
      setValidationError('')
    }

    return (
      <LocalizationProvider dateAdapter={AdapterDateFns}>
        <Box>
          <Grid container spacing={2}>
            <Grid size={12} sx={{ pb: 2 }}>
              <DescriptiveRadioButton options={rfpRadioButtonOptions} value={selectedOption} onChange={onChange} />
            </Grid>
            <Grid size={12}>
              <TextField
                select
                fullWidth
                label="Owner"
                value={formValues.owner}
                onChange={(e) => handleInputChange('owner', e.target.value)}
              >
                {ownerOptions.map((option: OwnerOption) => (
                  <MenuItem key={option.id} value={option.id}>
                    {option.name}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid size={12}>
              <TextField
                select
                fullWidth
                label="Collaborators"
                value={formValues.collaborators}
                onChange={(e) => handleInputChange('collaborators', e.target.value)}
              >
                {collaboratorsOptions.map((option) => (
                  <MenuItem key={option.value} value={option.value}>
                    {option.label}
                  </MenuItem>
                ))}
              </TextField>
            </Grid>

            <Grid size={6}>
              <DatePicker
                label="Quote Needed by*"
                format="MMMM d, yyyy"
                value={formValues.quoteNeededBy || undefined}
                onChange={(newValue) => handleDateChange('quoteNeededBy', newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </Grid>

            <Grid size={6}>
              <DatePicker
                label="Delivery Date"
                format="MMMM d, yyyy"
                value={formValues.deliveryDate ? new Date(formValues.deliveryDate) : undefined}
                onChange={(newValue) => handleDateChange('deliveryDate', newValue)}
                slotProps={{
                  textField: {
                    fullWidth: true,
                  },
                }}
              />
            </Grid>

            <Grid size={12}>
              <Typography variant="body2" color="error">
                {validationError}
              </Typography>
            </Grid>
          </Grid>

          <Grid size={12} sx={{ gap: 2, pt: 2, display: 'flex', justifyContent: 'flex-end' }}>
            <PrimaryCancelButton variant="text" color="inherit" onClick={handleClose}>
              Cancel & Close
            </PrimaryCancelButton>
            <DarkPrimaryButton variant="outlined" color="inherit" onClick={handleFormSubmission}>
              Save Changes
            </DarkPrimaryButton>
          </Grid>
        </Box>
      </LocalizationProvider>
    )
  }

  return (
    <Modal open={open} onClose={onClose} closeAfterTransition aria-labelledby="account-preferences-modal-title">
      <Box
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          backgroundColor: 'white',
          width: { xs: '90%', lg: 800 },
          maxHeight: 900,
          display: 'flex',
          flexDirection: 'column',
          overflowY: 'hidden',
        }}
      >
        <ModalHeader
          title={'Edit RFP Details'}
          variant="modalHeader2"
          onCloseButtonClick={handleClose}
          sx={{ pl: 6, pr: 5, py: 2 }}
          id="edit-rfp-details-modal-title"
        />
        <Stack p={6} pt={1}>
          <Typography variant="h4">This RFP is Part of the Following Job</Typography>
          <Stack
            sx={{
              border: '1px solid',
              borderColor: '#ccc',
              borderRadius: '8px',
              p: 1,
              mt: 2,
            }}
          >
            Job (this modal is not yet fully implemented)
          </Stack>
          <Typography variant="h4" sx={{ pt: 4, pb: 2 }}>
            RFP Details
          </Typography>

          <EditRfpDetailsForm />
        </Stack>
      </Box>
    </Modal>
  )
}
