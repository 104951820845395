import { Stack } from '@mui/material'
import Box from '@mui/material/Box'
import { RFPPageContent } from '@/components/rfps/draft/steps/build-rfp-step-content.tsx'
import { RFPAddVendorHelperModal } from '@/components/ui/modals/add_vendors_to_rfp_helper_modal.tsx'
import { useState } from 'react'
import VendorPanel from '@/components/rfps/draft/steps/vendor-panel.tsx'
import { OrganizationContact, UserContact } from '@/types.ts'
import AddVendorContactToRfpModal from '@/components/ui/modals/add-vendor-contact-to-rfp-modal.tsx'

export default function AddVendorsStep({ rfpData }: { rfpData: any }) {
  const addVendorRFPContentParentId = 'add-vendor-rfp-content-parent'
  const [helperModalOpen, setHelperModalOpen] = useState(true)
  const [vendorContactModalOpen, setVendorContactModalOpen] = useState(false)
  const [selectedVendorContact, setSelectedVendorContact] = useState<OrganizationContact | null>(null)
  const onUpdateRfpData = (updates: Partial<any>) => {
    console.log('onUpdateRfpData', updates)
  }

  // const createSentRfpMutation = useMutation({
  //   mutationFn: createSentRfp,
  // })

  const handleContactSelect = (contact: OrganizationContact) => {
    setSelectedVendorContact(contact)
    if (contact) {
      setVendorContactModalOpen(true)
    }
  }

  const handleAddVendorContacts = (contacts: UserContact[]) => {
    console.log('handleAddVendorContacts :: user contacts :', contacts)
    console.log('handleAddVendorContacts :: rfpData :', rfpData)
    console.log('handleAddVendorContacts :: selectedVendorContact :', selectedVendorContact)
    // createSentRfpMutation.mutate({
    //   rfpId: rfpData.id,
    //   organizationId: rfpData.organization.id,
    //   directedOrganizations: [
    //     {
    //       directedOrganization: selectedVendorContact?.id as string,
    //       directedOrganizationUsers: contacts.map((contact) => contact.id),
    //       send: true,
    //     },
    //   ],
    //   plants: [],
    // })
  }

  return (
    <Box sx={{ display: 'flex', height: '100%', width: '100%' }}>
      <Stack sx={{ width: 350, backgroundColor: 'white', p: 4 }}>
        <VendorPanel onContactSelect={handleContactSelect} />
      </Stack>
      <Stack
        direction="column"
        sx={{
          flexGrow: 1,
        }}
      >
        <Box
          id={addVendorRFPContentParentId}
          sx={{
            p: 3,
            flexGrow: 1,
            overflow: 'auto',
            scrollbarWidth: 'none',
            height: 0,
            position: 'relative',
          }}
        >
          <RFPAddVendorHelperModal
            containerId={addVendorRFPContentParentId}
            open={helperModalOpen}
            onClose={setHelperModalOpen}
          />
          <RFPPageContent rfpData={rfpData} onUpdateRfpData={onUpdateRfpData} editMode={false} />
        </Box>
      </Stack>
      <AddVendorContactToRfpModal
        open={vendorContactModalOpen}
        onClose={() => setVendorContactModalOpen(false)}
        vendorContact={selectedVendorContact}
        onAddVendorContacts={handleAddVendorContacts}
      />
    </Box>
  )
}
