import { createFileRoute } from '@tanstack/react-router'
import { Link } from '@tanstack/react-router'
import { MutableRefObject, useState } from 'react'

import {
  DataGridPremium,
  GridColDef,
  GridRenderCellParams,
  useGridApiRef,
  useKeepGroupedColumnsHidden,
} from '@mui/x-data-grid-premium'
import type { GridApiPremium } from '@mui/x-data-grid-premium/models/gridApiPremium'

import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'

import { Download } from 'lucide-react'

import ControlPanel from '@/components/ui/base/control-panel'
import PageLayout from '@/components/ui/layouts/page-layout.tsx'
import type { ElasticsearchResponse } from '@/types'

export const Route = createFileRoute('/_authenticated/rfps/')({
  component: RFPSIndex,
})

// Enhanced RFP type
type RFP = {
  id: string
  rfpNumber: string
  jobName: string
  status: string
  itemsQuoted: string
  sent: string
  quoteDue: string
  jobDate: string
  vendor: string
  isDraft: boolean
}

// Dummy data
const dummyRFPs: RFP[] = [
  {
    id: '323341',
    rfpNumber: 'RFP - #323341',
    jobName: 'JOB NAME - #112143',
    status: 'Viewed - Jun 26, 5:45pm',
    itemsQuoted: '45 of 45',
    sent: '22 days ago',
    quoteDue: '2024-07-15',
    jobDate: '2024-08-01',
    vendor: 'Evergreen Gardens',
    isDraft: false,
  },
  {
    id: '333425',
    rfpNumber: 'RFP - #333425',
    jobName: 'JOB NAME - #77165',
    status: 'Quote Received - Jul 1, 5:55pm',
    itemsQuoted: '2 of 20',
    sent: '3 days ago',
    quoteDue: '2024-07-15',
    jobDate: '2024-08-01',
    vendor: 'Evergreen Gardens',
    isDraft: false,
  },
  {
    id: '466574',
    rfpNumber: 'RFP - #466574',
    jobName: 'JOB NAME - #77165',
    status: 'Quote Received - Jul 2, 1:33pm',
    itemsQuoted: '45 of 45',
    sent: '22 days ago',
    quoteDue: '2024-07-15',
    jobDate: '2024-08-01',
    vendor: 'Evergreen Gardens',
    isDraft: false,
  },
  {
    id: '445512',
    rfpNumber: 'RFP - #445512',
    jobName: 'JOB NAME - #98732',
    status: 'Draft',
    itemsQuoted: '0 of 32',
    sent: '-',
    quoteDue: '2024-07-20',
    jobDate: '2024-08-15',
    vendor: 'Drafts',
    isDraft: true,
  },
  {
    id: '445513',
    rfpNumber: 'RFP - #445513',
    jobName: 'JOB NAME - #98733',
    status: 'Draft',
    itemsQuoted: '12 of 50',
    sent: '-',
    quoteDue: '2024-07-25',
    jobDate: '2024-08-20',
    vendor: 'Drafts',
    isDraft: true,
  },
  {
    id: '334455',
    rfpNumber: 'RFP - #334455',
    jobName: 'JOB NAME - #65432',
    status: 'Viewed - Jul 1, 2:30pm',
    itemsQuoted: '15 of 30',
    sent: '5 days ago',
    quoteDue: '2024-07-18',
    jobDate: '2024-08-05',
    vendor: "Amanda's Nursery",
    isDraft: false,
  },
  {
    id: '556677',
    rfpNumber: 'RFP - #556677',
    jobName: 'JOB NAME - #87654',
    status: 'Not Viewed',
    itemsQuoted: '0 of 25',
    sent: '1 day ago',
    quoteDue: '2024-07-30',
    jobDate: '2024-08-25',
    vendor: 'Green Haven Nursery',
    isDraft: false,
  },
  {
    id: '667788',
    rfpNumber: 'RFP - #667788',
    jobName: 'JOB NAME - #34567',
    status: 'Quote Received - Jul 3, 9:15am',
    itemsQuoted: '40 of 40',
    sent: '10 days ago',
    quoteDue: '2024-07-10',
    jobDate: '2024-07-30',
    vendor: 'Red Rose LLC',
    isDraft: false,
  },
  {
    id: '778899',
    rfpNumber: 'RFP - #778899',
    jobName: 'JOB NAME - #23456',
    status: 'Viewed - Jul 2, 11:20am',
    itemsQuoted: '28 of 35',
    sent: '4 days ago',
    quoteDue: '2024-07-22',
    jobDate: '2024-08-10',
    vendor: 'Vibe Gardens',
    isDraft: false,
  },
  {
    id: '445514',
    rfpNumber: 'RFP - #445514',
    jobName: 'JOB NAME - #98734',
    status: 'Draft',
    itemsQuoted: '5 of 28',
    sent: '-',
    quoteDue: '2024-07-28',
    jobDate: '2024-08-22',
    vendor: 'Drafts',
    isDraft: true,
  },
]

const columns: GridColDef[] = [
  {
    field: 'rfpNumber',
    headerName: 'RFP Number',
    width: 200,
    renderCell: (params: GridRenderCellParams) => (
      <Link
        to={params.row.isDraft ? '/rfps/draft/$rfpId' : ''}
        params={{ rfpId: params.row.id }}
        className="text-blue-600 hover:underline"
      >
        {params.value}
      </Link>
    ),
    groupable: false,
  },
  { field: 'jobName', headerName: 'Job Name', width: 200 },
  { field: 'status', headerName: 'Status', width: 200, groupable: false },
  {
    field: 'itemsQuoted',
    headerName: 'Items Quoted',
    width: 150,
    groupable: false,
  },
  { field: 'sent', headerName: 'Sent', width: 150, groupable: false },
  { field: 'quoteDue', headerName: 'Quote Due', width: 150, groupable: false },
  { field: 'jobDate', headerName: 'Job Date', width: 150, groupable: false },
  {
    field: 'vendor',
    headerName: 'Vendor',
    width: 200,
  },
]

function RFPSIndex() {
  const [activeTab, setActiveTab] = useState('sent-rfps')
  const [_searchResults, setSearchResults] = useState<ElasticsearchResponse<RFP> | null>(null)
  const apiRef = useGridApiRef() as MutableRefObject<GridApiPremium>
  const initialState = useKeepGroupedColumnsHidden({
    apiRef,
    initialState: {
      rowGrouping: {
        model: ['vendor', 'jobName'],
      },
    },
  })

  return (
    <PageLayout title="RFPs" icon={<Download size={36} />} globalNewButton>
      <ControlPanel
        tabConfig={{
          tabs: [
            { label: 'Sent RFPs', value: 'sent-rfps' },
            { label: 'Received RFPs', value: 'received-rfps' },
          ],
          activeTab: activeTab,
          onTabChange: setActiveTab,
        }}
        searchConfig={{
          placeholder: 'Search RFPs...',
          searchUrl: '/v1/core/rfps/search/',
          onSearchResults: (response) => setSearchResults(response as ElasticsearchResponse<any>),
        }}
      />
      <Typography variant="strongMd">
        NOTE: This page is still a work in progress and uses fake data for rendering.
      </Typography>
      <Box sx={{ height: 600, width: '100%' }}>
        <DataGridPremium rows={dummyRFPs} columns={columns} apiRef={apiRef} initialState={initialState} />
      </Box>
    </PageLayout>
  )
}
