import Box from '@mui/material/Box'
import { NotepadText } from 'lucide-react'
import { RFPPageContent } from '@/components/rfps/draft/steps/build-rfp-step-content.tsx'
import { RfpSideDrawer } from '@/components/rfps/drawer/rfp-side-drawer.tsx'
import { RfpPageTitle } from '@/components/rfps/rfp-page-title.tsx'

interface BuildRFPStepProps {
  rfpData: any
  onUpdateRfpData: (updates: Partial<any>) => void
}

export default function BuildRFPStep({ rfpData, onUpdateRfpData }: BuildRFPStepProps) {
  const addInternalNote = () => {
    const condition = false
    if (condition) {
      onUpdateRfpData({})
    }
  }

  return (
    <Box sx={{ display: 'flex', height: '100%' }}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          flexGrow: 1,
        }}
      >
        <RfpPageTitle
          title={'Add Plants & RFP Details'}
          actionItems={[
            {
              text: 'Add Internal Note',
              onClick: addInternalNote,
              startIcon: <NotepadText />,
            },
          ]}
        />
        <Box
          sx={{
            px: 3,
            pb: 3,
            flexGrow: 1,
            overflow: 'auto',
            scrollbarWidth: 'none',
            height: 0, // Take up full height of parent
          }}
        >
          <RFPPageContent rfpData={rfpData} onUpdateRfpData={onUpdateRfpData} editMode={true} />
        </Box>
      </Box>
      <RfpSideDrawer />
    </Box>
  )
}
