import { Stepper, Typography, Box, Stack, Button, Step, StepLabel, Fade } from '@mui/material'
import { ReactNode, ComponentType, useEffect, useState } from 'react'
import { ChevronLeft } from 'lucide-react'
import theme from '@/theme.ts'

export type StepConfig<Props = any> = {
  key: string
  label: string
  component: ComponentType<Props>
  canProgress?: () => boolean
}

interface StepLayoutProps {
  title: string
  steps: StepConfig[]
  currentStep: string
  onStepChange: (step: string) => Promise<void>
  saveStatus?: 'saved' | 'saving' | 'error' | 'none'
  actionButtons?: ReactNode
  children: ReactNode
}

export default function StepLayout({
  title,
  steps,
  currentStep,
  onStepChange,
  saveStatus,
  actionButtons,
  children,
}: StepLayoutProps) {
  const activeStepIndex = steps.findIndex((step) => step.key === currentStep)
  const currentStepConfig = steps[activeStepIndex]

  const handleStepClick = async (clickedStep: StepConfig, clickedIndex: number) => {
    if (clickedIndex < activeStepIndex) {
      await onStepChange(clickedStep.key)
    }
  }

  const CustomStepperComponent = () => {
    return (
      <Stepper
        activeStep={activeStepIndex}
        sx={{
          '& .MuiStepIcon-root': {
            display: 'none',
          },
        }}
      >
        {steps.map((step, index) => (
          <Step
            key={step.key}
            sx={{
              cursor: index < activeStepIndex ? 'pointer' : 'default',
              '& .Mui-completed': {
                opacity: index == activeStepIndex ? '1' : '0.5',
                fontWeight: index == activeStepIndex ? 'bold' : 'normal',
              },
            }}
            onClick={() => handleStepClick(step, index)}
          >
            <StepLabel>{`${index + 1}. ${step.label}`}</StepLabel>
          </Step>
        ))}
      </Stepper>
    )
  }

  const PreviousStepChevronButton = () => {
    return (
      <Button
        variant="contained"
        onClick={async () => {
          const previousStep = steps[activeStepIndex - 1]
          if (previousStep) {
            await onStepChange(previousStep.key)
          }
        }}
        sx={{
          minWidth: 0,
          padding: '10px 12px',
          borderRadius: '8px',
          border: '1px solid #C6CFD6',
          bgcolor: '#EEF2F5',
          boxShadow: 'none',
          ':hover': {
            bgcolor: '#E3E9ED',
            boxShadow: 'none',
          },
          color: theme.palette.black.main,
        }}
      >
        <ChevronLeft size={22} />
      </Button>
    )
  }

  const NextStepButton = () => {
    return (
      <Button
        variant="contained"
        disabled={!currentStepConfig?.canProgress?.()}
        onClick={async () => {
          const nextStep = steps[activeStepIndex + 1]
          if (nextStep) {
            await onStepChange(nextStep.key)
          }
        }}
        sx={{
          minWidth: 200,
          padding: '10px 20px',
          borderRadius: '8px',
          boxShadow: 'none',
          ':hover': {
            boxShadow: 'none',
          },
        }}
      >
        <Typography variant="body1" sx={{ fontWeight: 700 }}>
          Next: {steps[activeStepIndex + 1].label}
        </Typography>
      </Button>
    )
  }

  // Add state for controlling visibility
  const [showSaveStatus, setShowSaveStatus] = useState(false)

  useEffect(() => {
    if (saveStatus === 'saving' || saveStatus === 'error') {
      setShowSaveStatus(true)
    } else if (saveStatus === 'saved') {
      setShowSaveStatus(true)
      // Hide the message after 3 seconds
      const timer = setTimeout(() => {
        setShowSaveStatus(false)
      }, 3000)
      return () => clearTimeout(timer)
    }
  }, [saveStatus])

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        bgcolor: '#EEF2F5',
        display: 'flex',
        flexDirection: 'column',
      }}
    >
      <Box
        sx={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          bgcolor: 'white',
          p: 3,
          borderBottom: '3px solid #EEF2F5',
        }}
      >
        <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
          <Typography variant="h1" fontWeight={400}>
            {title}
          </Typography>
          <CustomStepperComponent />
        </Box>
        <Stack direction="row" spacing={2} sx={{ alignItems: 'center' }}>
          <Fade in={showSaveStatus} timeout={500}>
            <Typography
              variant="body2"
              sx={{
                color: saveStatus === 'error' ? 'error.main' : 'text.secondary',
                mr: 2,
                minWidth: '100px', // Prevent layout shift
              }}
            >
              {saveStatus === 'saving' ? 'Saving...' : saveStatus === 'error' ? 'Error saving' : 'All changes saved'}
            </Typography>
          </Fade>
          {activeStepIndex > 0 && <PreviousStepChevronButton />}
          {actionButtons}
          {currentStepConfig?.canProgress && <NextStepButton />}
        </Stack>
      </Box>

      <Box sx={{ flex: 1 }}>{children}</Box>
    </Box>
  )
}
