import { useState, ChangeEvent, Fragment } from 'react'
import Box from '@mui/material/Box'
import Grid from '@mui/material/Grid'
import MenuItem from '@mui/material/MenuItem'
import TextField from '@mui/material/TextField'
import Typography from '@mui/material/Typography'
import { UserProfile } from '@/types.ts'
import { initialFormAddress, initialFormEmail, initialFormPhone } from '@/seed_form_data.ts'
import Select, { SelectChangeEvent } from '@mui/material/Select'
import { countryFormattedPhoneNumber } from '@/lib/utils.ts'
import { ContactAddressTypes, ContactEmailTypes, ContactPhoneTypes, US_STATES } from '@/constants.ts'
import { PlusIcon } from 'lucide-react'
import Alert from '@mui/material/Alert'
import { isPlainArray } from '@tanstack/react-router'
import Avatar from '@mui/material/Avatar'
import { DarkPrimaryButton, PrimaryCancelButton } from '@/components/ui/base/buttons/buttons.tsx'
import Button from '@mui/material/Button'
import { USStatesMenuProps } from '@/components/organization-settings-and-members/organization-location-form.tsx'

type MyProfileFormProps = {
  user: UserProfile
  validationError: string | null
  onCancel: (value: boolean) => void
  onSubmit: (value: any) => void
  currentProfilePicture: string | null
  setProfilePicture: (value: File | null) => void
}

export default function MyProfileForm({
  user,
  validationError,
  onCancel,
  onSubmit,
  currentProfilePicture,
  setProfilePicture,
}: MyProfileFormProps) {
  const [formProfilePicture, setFormProfilePicture] = useState(currentProfilePicture || '')
  const [formStreetAddress, setFormStreetAddress] = useState(
    user.address.length && isPlainArray(user.address) ? user.address?.[0]?.address?.street : ''
  )
  const [formDetails, setFormDetails] = useState<UserProfile>({
    ...user,
    address: user.address.length && isPlainArray(user.address) ? user.address : [initialFormAddress],
  })
  const [formSuiteNumber, setFormSuiteNumber] = useState('')
  const [phoneNumbers, setPhoneNumbers] = useState(
    user.phone.length && isPlainArray(user.phone) ? user.phone : [initialFormPhone]
  )
  const [emailAddresses, setEmailAddresses] = useState(
    user.other_email.length && isPlainArray(user.other_email) ? user.other_email : [initialFormEmail]
  )

  const handleEmailOrPhoneValueChange = (
    e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement> | SelectChangeEvent<unknown>,
    index: number,
    contact_field: string
  ) => {
    const { name, value } = e.target
    if (contact_field === 'phone') {
      const updatedPhoneNumbers = [...phoneNumbers]
      let parsedValue = value
      if (name === 'number') {
        parsedValue = countryFormattedPhoneNumber(value as string)
      }
      updatedPhoneNumbers[index] = {
        ...updatedPhoneNumbers[index],
        [name]: parsedValue,
      }
      setPhoneNumbers(updatedPhoneNumbers)
      setFormDetails((prevState) => {
        const updatedState = {
          ...prevState,
          phone: updatedPhoneNumbers,
        }
        return updatedState as UserProfile
      })
    } else {
      const updatedEmailAddresses = [...emailAddresses]
      updatedEmailAddresses[index] = {
        ...updatedEmailAddresses[index],
        [name]: value,
      }
      setEmailAddresses(updatedEmailAddresses)
      setFormDetails((prevState) => {
        const updatedState = {
          ...prevState,
          other_email: updatedEmailAddresses,
        }
        return updatedState as UserProfile
      })
    }
  }

  const handleInputChange = (e: ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    const { name, value } = e.target
    setFormDetails((prevState) => {
      const updatedState = {
        ...prevState,
        [name]: value,
      }
      return updatedState as UserProfile
    })
  }

  const addEmptyPhoneOrEmail = (type: 'phone' | 'email') => {
    if (type === 'phone') {
      setPhoneNumbers([...phoneNumbers, initialFormPhone])
    } else {
      setEmailAddresses([...emailAddresses, initialFormEmail])
    }
  }

  const handleAddressInputChange = (
    e: ChangeEvent<HTMLInputElement | HTMLTextAreaElement> | SelectChangeEvent<unknown>
  ) => {
    const { name, value } = e.target
    const isStreetOrSuite = name === 'street' || name === 'suite_number'
    const updatedStreetAddress = name === 'street' ? value : formStreetAddress
    const updatedSuiteNumber = name === 'suite_number' ? value : formSuiteNumber

    const updatedValue = isStreetOrSuite ? `${updatedStreetAddress} ${updatedSuiteNumber}`.trim() : value

    const address_key = isStreetOrSuite ? 'street' : name

    if (isStreetOrSuite) {
      name === 'street' ? setFormStreetAddress(value as string) : setFormSuiteNumber(value as string)
    }

    // Update the formLocationDetails state
    setFormDetails((prevState) => {
      const updatedAddresses = [...(prevState.address || [initialFormAddress])]
      const updatedAddress = {
        ...updatedAddresses[0],
        address: {
          ...updatedAddresses[0].address,
          [address_key]: updatedValue,
        },
      }

      if (name === 'address_type') {
        updatedAddress.address_type = value as ContactAddressTypes
      }

      updatedAddresses[0] = updatedAddress

      const updatedState = {
        ...prevState,
        address: updatedAddresses,
      }

      return updatedState as UserProfile
    })
  }

  const handlePictureUpload = (e: ChangeEvent<HTMLInputElement>) => {
    const file = e.target?.files?.[0]
    if (file) {
      setFormProfilePicture(URL.createObjectURL(file) as string)
      setProfilePicture(file as File)
    }
  }
  const handlePictureRemove = () => {
    setFormProfilePicture('')
    setProfilePicture(null)
    setFormDetails((prevState) => ({
      ...prevState,
      profile_picture: null,
    }))
  }

  return (
    <Box sx={{ display: 'flex', flexDirection: 'column', gap: 6 }}>
      <Grid container spacing={2}>
        <Grid item xs={12} md={8} container spacing={2}>
          <Grid item xs={12}>
            <Typography variant="tabSection">Name & Title</Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="first_name"
              label="First Name"
              placeholder="First Name"
              fullWidth
              value={formDetails.first_name || ''}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="last_name"
              label="Last Name"
              placeholder="Last Name"
              fullWidth
              value={formDetails.last_name || ''}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="title"
              label="Title"
              placeholder="Title"
              fullWidth
              value={formDetails.title || ''}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              variant="outlined"
              name="biography"
              label="Bio"
              placeholder="Bio"
              fullWidth
              multiline
              rows={5}
              value={formDetails.biography || ''}
              onChange={(e) => handleInputChange(e)}
            />
          </Grid>
        </Grid>
        <Grid item xs={12} md={4}>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              justifyContent: 'center',
              alignItems: 'end',
            }}
          >
            <Avatar
              alt="PB"
              src={formProfilePicture || ''}
              sx={{
                width: 120,
                height: 120,
                mb: 2,
                border: 1,
                backgroundColor: '#F0F0F0',
                borderRadius: 1,
              }}
            />
            <Box display="flex" mt={2}>
              <Typography
                component="label"
                variant="body1"
                sx={{
                  cursor: 'pointer',
                  mr: 2,
                  color: 'primary.main',
                  textDecoration: 'underline',
                }}
              >
                Upload New
                <input type="file" accept="image/*" onChange={handlePictureUpload} style={{ display: 'none' }} />
              </Typography>

              <Typography
                variant="body1"
                sx={{
                  cursor: 'pointer',
                  color: 'primary.main',
                  textDecoration: 'underline',
                }}
                onClick={handlePictureRemove}
              >
                Remove
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={12}>
          <Typography variant="tabSection">Contact Details</Typography>
        </Grid>
        {phoneNumbers.map((phone, index) => {
          return (
            <Fragment key={phone.id}>
              <Grid item xs={6}>
                <TextField
                  variant="outlined"
                  name="number"
                  label="Phone"
                  placeholder="+ (---) --- ----"
                  fullWidth
                  value={phone.number || ''}
                  onChange={(e) => handleEmailOrPhoneValueChange(e, index, 'phone')}
                />
              </Grid>
              <Grid item xs={3}>
                <TextField
                  variant="outlined"
                  name="extension"
                  label="Extension"
                  placeholder="-"
                  fullWidth
                  value={phone.extension || ''}
                  onChange={(e) => handleEmailOrPhoneValueChange(e, index, 'phone')}
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  name="contact_type"
                  fullWidth
                  value={phone.contact_type || ContactPhoneTypes.WORK}
                  onChange={(e) => handleEmailOrPhoneValueChange(e, index, 'phone')}
                >
                  {Object.values(ContactPhoneTypes).map((value, phone_index) => (
                    <MenuItem key={`phone-type-${phone_index}`} value={value}>
                      {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Fragment>
          )
        })}
        <Grid item xs={12} sx={{ display: 'flex', paddingTop: '3px !important' }}>
          <Button onClick={() => addEmptyPhoneOrEmail('phone')} color="inherit">
            <Box display="flex" alignItems="center" gap={1}>
              <PlusIcon size={16} />
              <Typography variant="button">Add another phone number</Typography>
            </Box>
          </Button>
        </Grid>
        {emailAddresses.map((email, index) => {
          return (
            <Fragment key={email.id}>
              <Grid item xs={9}>
                <TextField
                  variant="outlined"
                  name="address"
                  label="Email"
                  placeholder="info@acme.co"
                  fullWidth
                  value={email.address || ''}
                  onChange={(e) => handleEmailOrPhoneValueChange(e, index, 'other_email')}
                />
              </Grid>
              <Grid item xs={3}>
                <Select
                  name="contact_type"
                  fullWidth
                  value={email.contact_type || ContactEmailTypes.WORK}
                  onChange={(e) => handleEmailOrPhoneValueChange(e, index, 'other_email')}
                >
                  {Object.values(ContactEmailTypes).map((value, email_index) => (
                    <MenuItem key={`email-type-${email_index}`} value={value}>
                      {value.charAt(0).toUpperCase() + value.slice(1).toLowerCase()}
                    </MenuItem>
                  ))}
                </Select>
              </Grid>
            </Fragment>
          )
        })}
        <Grid item xs={12} sx={{ display: 'flex', paddingTop: '3px !important' }}>
          <Button onClick={() => addEmptyPhoneOrEmail('email')} color="inherit">
            <Box display="flex" alignItems="center" gap={1}>
              <PlusIcon size={16} />
              <Typography variant="button">Add another email address</Typography>
            </Box>
          </Button>
        </Grid>
      </Grid>
      <Grid container spacing={2}>
        <Grid item xs={9}>
          <TextField
            variant="outlined"
            name="street"
            label="Street Address"
            placeholder="Address"
            fullWidth
            value={formStreetAddress}
            onChange={handleAddressInputChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            name="suite_number"
            label="Suite"
            placeholder="Suite #"
            fullWidth
            value={formSuiteNumber}
            onChange={handleAddressInputChange}
          />
        </Grid>
        <Grid item xs={6}>
          <TextField
            variant="outlined"
            name="city"
            label="City"
            placeholder="City Name"
            fullWidth
            value={formDetails?.address?.[0].address?.city || ''}
            onChange={handleAddressInputChange}
          />
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            name="state"
            label="State"
            fullWidth
            select
            value={formDetails?.address?.[0].address?.state || ''}
            onChange={handleAddressInputChange}
            slotProps={{ select: { MenuProps: USStatesMenuProps } }}
          >
            {US_STATES.map((state) => (
              <MenuItem key={state} value={state}>
                {state}
              </MenuItem>
            ))}
          </TextField>
        </Grid>
        <Grid item xs={3}>
          <TextField
            variant="outlined"
            name="zip"
            label="Zip Code"
            required
            fullWidth
            value={formDetails?.address?.[0].address?.zip || ''}
            onChange={handleAddressInputChange}
          />
        </Grid>
        <Grid item xs={12}>
          <Box style={{ display: 'flex', gap: '8px', paddingTop: '16px' }}>
            <DarkPrimaryButton variant="outlined" color="inherit" onClick={() => onSubmit(formDetails)}>
              Save Changes
            </DarkPrimaryButton>
            <PrimaryCancelButton variant="outlined" color="inherit" onClick={() => onCancel(false)}>
              Cancel
            </PrimaryCancelButton>
          </Box>
        </Grid>
        {validationError && (
          <Grid item xs={12}>
            <Alert severity="error" className="my-4">
              {validationError}
            </Alert>
          </Grid>
        )}
      </Grid>
    </Box>
  )
}
