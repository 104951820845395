import api from './api'
import { PlantListEntry } from '@/types.ts'

export async function createRfp(organizationId: string) {
  const response = await api.post('/v1/core/rfp/', {
    organization: organizationId,
    name: 'New RFP',
  })

  // TODO: type this
  return response.data as any
}

export async function getRfp(rfpId: string) {
  const response = await api.get(`/v1/core/rfp/${rfpId}`)

  // TODO: type this
  return response.data as any
}

export async function updateRfp(rfpId: string, data: any) {
  const response = await api.put(`/v1/core/rfp/${rfpId}`, data)

  // TODO: type this
  return response.data as any
}

export type CreateSentRfpRequest = {
  rfpId: string
  organizationId: string
  directedOrganizations: {
    directedOrganization: string
    directedOrganizationUsers: string[]
    notificationSubject?: string
    notificationBody?: string
    send?: boolean
  }[]
  plants: PlantListEntry[]
}
export async function createSentRfp({ rfpId, organizationId, directedOrganizations, plants }: CreateSentRfpRequest) {
  const response = await api.post('/v1/core/sent_rfp/', {
    organization: organizationId,
    rfp_id: rfpId,
    directed_organizations: directedOrganizations,
    plants,
  })

  return response.data as any
}
