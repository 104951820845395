import { useState, useEffect, Fragment, MouseEvent } from 'react'
import { IconButton, Menu, MenuItem } from '@mui/material'
import MoreVertIcon from '@mui/icons-material/MoreVert'
import { CirclePlus, Pencil, X } from 'lucide-react'
import Box from '@mui/material/Box'
import OrganizationLocationForm from '@/components/organization-settings-and-members/organization-location-form.tsx'
import { useMutation, useQueryClient } from '@tanstack/react-query'
import {
  createOrganizationLocation,
  deleteOrganizationLocation,
  updateOrganizationLocation,
} from '@/api/organization_management.ts'
import {
  ContactEmail,
  ContactPhone,
  CreateOrganizationLocationRequestBody,
  Organization,
  OrganizationLocation,
} from '@/types.ts'
import { OrganizationLocationSchema } from '@/lib/validation-schemas.ts'
import { trimValues } from '@/lib/utils.ts'
import { z } from 'zod'
import { initialFormLocation } from '@/seed_form_data.ts'
import { DarkPrimaryButton } from '@/components/ui/base/buttons/buttons.tsx'
import Typography from '@mui/material/Typography'
import Divider from '@mui/material/Divider'
import Alert from '@mui/material/Alert'

type LocationsProps = {
  organization: Organization | undefined
  refetch_organization: () => void
}

export default function Locations({ organization, refetch_organization }: LocationsProps) {
  const queryClient = useQueryClient()
  const [locations, setLocations] = useState([] as OrganizationLocation[])
  const [validationError, setValidationError] = useState<string | null>(null)
  const [organizationLocationForm, setOrganizationLocationForm] = useState('')

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const [currentLocationId, setCurrentLocationId] = useState<string | null>(null)

  useEffect(() => {
    if (organization?.locations) {
      setLocations(organization.locations.filter((location) => !location.deleted_at))
    }
  }, [organization])

  // Mutations
  const createLocationDetailsMutation = useMutation({
    mutationFn: createOrganizationLocation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['new-location'],
      })
      refetch_organization()
      setOrganizationLocationForm('')
    },
  })

  const updateLocationDetailsMutation = useMutation({
    mutationFn: updateOrganizationLocation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['update-location'] /* find significance of this */,
      })
      refetch_organization()
      setOrganizationLocationForm('')
    },
  })

  const deleteLocationMutation = useMutation({
    mutationFn: deleteOrganizationLocation,
    onSuccess: async () => {
      await queryClient.invalidateQueries({
        queryKey: ['delete-location'],
      })
      refetch_organization()
      setOrganizationLocationForm('')
    },
  })

  // Event Handlers
  const handleClick = (event: MouseEvent<HTMLElement>, locationId: string | null) => {
    setAnchorEl(event.currentTarget)
    setCurrentLocationId(locationId) // Set the current location ID
  }

  const handleClose = () => {
    setAnchorEl(null)
    setCurrentLocationId(null)
  }

  const handleEditLocation = (locationId: string) => {
    setAnchorEl(null)
    toggleOrganizationLocationForm(locationId)
  }

  const toggleOrganizationLocationForm = (locationId: string) => {
    setOrganizationLocationForm(locationId)
    setValidationError(null)
  }

  const validateForm = (data: CreateOrganizationLocationRequestBody): boolean => {
    try {
      const schema = OrganizationLocationSchema
      schema.parse(data)
      setValidationError(null)
      return true
    } catch (error) {
      if (error instanceof z.ZodError) {
        setValidationError(error.errors[0].message)
      } else {
        setValidationError('An unknown error occurred')
      }
      return false
    }
  }

  const cleanseLocationFormData = (location: OrganizationLocation | CreateOrganizationLocationRequestBody) => {
    const requestData = trimValues(location)
    // remove empty phone numbers
    requestData.phone = requestData.phone.filter((phone: ContactPhone) => phone.number)
    // remove empty email addresses
    requestData.email = requestData.email.filter((email: ContactEmail) => email.address)
    return requestData
  }

  const submitLocationDetails = async (location: OrganizationLocation | CreateOrganizationLocationRequestBody) => {
    // call mutation to save location details
    const requestData = cleanseLocationFormData(location)
    requestData.organization = organization?.id

    if (validateForm(requestData)) {
      try {
        await createLocationDetailsMutation.mutateAsync(requestData)
      } catch (error) {
        console.error('Error saving location:', error)
        setValidationError(`Failed to save location. Please try again. ${error}`)
      }
    }
  }

  const updateLocationDetails = async (location: OrganizationLocation | CreateOrganizationLocationRequestBody) => {
    // call mutation to update location details
    const requestData = cleanseLocationFormData(location)
    if (typeof location?.organization === 'object') {
      requestData.organization = location.organization?.id || null
    } else {
      requestData.organization = location.organization || null
    }

    if (validateForm(requestData)) {
      try {
        await updateLocationDetailsMutation.mutateAsync(requestData)
      } catch (error) {
        console.error('Error saving location:', error)
        setValidationError(`Failed to save location. Please try again. ${error}`)
      }
    }
  }

  const handleRemoveLocation = async (locationId: string) => {
    setAnchorEl(null)

    try {
      await deleteLocationMutation.mutateAsync(locationId)
    } catch (error) {
      console.error('Error removing location:', error)
      setValidationError(`Failed to remove location. Please try again. ${error}`)
    }
  }

  const locationHamburgerMenu = (locationId: string) => {
    return (
      <Box key={locationId}>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => handleClick(event, locationId)} // Pass location id to handleClick
          sx={{ border: 1, borderRadius: 1, paddingX: 0, paddingY: 0.5 }}
        >
          <MoreVertIcon />
        </IconButton>
        <Menu
          id="long-menu"
          anchorEl={anchorEl}
          open={Boolean(anchorEl) && currentLocationId === locationId} // Ensure menu opens only for current location
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
        >
          <MenuItem onClick={() => handleEditLocation(locationId)}>
            <Box display="flex" alignItems="center" gap={1}>
              <Pencil size={20} />
              <Typography variant="body1">Edit Location Details</Typography>
            </Box>
          </MenuItem>
          <MenuItem onClick={() => handleRemoveLocation(locationId)}>
            <Box display="flex" alignItems="center" gap={1}>
              <X size={20} />
              <Typography variant="body1">Remove Location</Typography>
            </Box>
          </MenuItem>
        </Menu>
      </Box>
    )
  }

  return (
    <>
      <Box display="flex" alignItems="center" justifyContent="space-between" mb={3}>
        <Typography variant="tabHeader">Locations</Typography>
        <DarkPrimaryButton
          onClick={() => toggleOrganizationLocationForm('new-location')}
          disabled={Boolean(organizationLocationForm)}
        >
          <Box display="flex" alignItems="center" gap={1}>
            <CirclePlus size="16" />
            <Typography variant="button">Add Location</Typography>
          </Box>
        </DarkPrimaryButton>
      </Box>

      <Divider />

      {/* add location form */}
      {organizationLocationForm == 'new-location' && (
        <OrganizationLocationForm
          validationError={validationError}
          location={initialFormLocation}
          toggleLocationDetails={toggleOrganizationLocationForm}
          submitLocationDetails={submitLocationDetails}
        />
      )}

      {/* no locations message */}
      {locations?.length === 0 && !organizationLocationForm && (
        <Alert severity="info" variant="outlined" sx={{ width: 'fit-content', mx: 'auto', my: 8 }}>
          No locations available for this organization, to add one click the &quot;Add Location&quot; button.
        </Alert>
      )}

      {/* location list */}
      {locations
        ?.filter((location) => !location.deleted_at) // Filter out deleted locations
        .map((location, index) => (
          <Fragment key={location.id}>
            {organizationLocationForm === location.id ? (
              <OrganizationLocationForm
                key={index}
                location={location}
                validationError={validationError}
                toggleLocationDetails={toggleOrganizationLocationForm}
                submitLocationDetails={updateLocationDetails}
              />
            ) : (
              <Box key={index}>
                <Box display="flex" alignItems="start" justifyContent="space-between" py={8}>
                  <Box display="flex" justifyContent="start" gap={4}>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" gap={2}>
                      <Typography variant="tabSection" textTransform="capitalize">
                        {location?.address ? location.address?.address_type : ''} :{' '}
                        {location.name ? location.name : 'n/a'}
                      </Typography>
                      <Box>
                        <Typography variant="tabSubsection">Address</Typography>
                        <Typography variant="body1">
                          {location.address ? location.address.address?.street : ''}
                        </Typography>
                        <Typography variant="body1">
                          {[
                            location?.address?.address?.city,
                            location?.address?.address?.state,
                            location?.address?.address?.zip,
                          ]
                            .filter(Boolean)
                            .join(', ')}
                        </Typography>
                      </Box>
                    </Box>
                    <Box display="flex" flexDirection="column" justifyContent="space-between" gap={2}>
                      <Box>
                        <Typography variant="tabSubsection">Phone</Typography>
                        {location.phone && location.phone.length > 0 ? (
                          location.phone?.map((phone, index) => (
                            <Typography variant="body1" key={index}>
                              {phone.number}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="body1">n/a</Typography>
                        )}
                      </Box>
                      <Box>
                        <Typography variant="tabSubsection">Email</Typography>
                        {location.email && location.email.length > 0 ? (
                          location.email.map((email, index) => (
                            <Typography variant="body1" key={index}>
                              {email.address || 'n/a'}
                            </Typography>
                          ))
                        ) : (
                          <Typography variant="body1">n/a</Typography>
                        )}
                      </Box>
                    </Box>
                  </Box>
                  {locationHamburgerMenu(location?.id || '')}
                </Box>
                <Divider />
              </Box>
            )}
          </Fragment>
        ))}
    </>
  )
}
