import Box from '@mui/material/Box'
import theme from '@/theme.ts'
import Typography from '@mui/material/Typography'
import { OrganizationDetails } from '@/components/ui/organization-details.tsx'
import { Stack } from '@mui/material'
import { RFPDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-rfp-details.tsx'
import { JobDetails } from '@/components/rfps/draft/steps/build-rfp-step-content-job-details.tsx'
import type { RFPStepProps } from '@/components/rfps/types.ts'
import { MouseEvent, useCallback, useState } from 'react'
import Button from '@mui/material/Button'
import { Add, AddCircle } from '@mui/icons-material'
import { ConfirmationModalProps, Job, PlantListEntry, PlantWithQuantity } from '@/types.ts'
import Menu from '@mui/material/Menu'
import MenuItem from '@mui/material/MenuItem'
import Divider from '@mui/material/Divider'
import { DEFAULT_PLANT_LIST_ENTRY } from '@/constants.ts'
import AddPlantsModal from '@/components/ui/modals/add-plants-modal.tsx'
import { CustomDivider } from '@/components/ui/base/dividers.tsx'
import RFPComments from '@/components/rfps/rfp-comments.tsx'
import AddPlantsFromListModal from '@/components/ui/modals/add-plants-from-list-modal.tsx'
import EditRfpDetailsModal from '@/components/ui/modals/edit-rfp-details-modal'
import RfpPlantTable from '@/components/rfps/rfp-plant-table.tsx'
import IconButton from '@mui/material/IconButton'
import { EllipsisVertical } from 'lucide-react'
import { generateObjectId, excludeDeletedItems, formatStringToShortMonthDayYear } from '@/lib/utils.ts'
import AddPlantsFromJobsModal from '@/components/ui/modals/add-plants-from-jobs-modal'
import { ConfirmationModal } from '@/components/ui/modals/confirmation-modal.tsx'
import CreatePlantListForRFPModal from '@/components/rfps/modals/create-plant-list-for-rfp-modal.tsx'

export const RFPPageContent = ({ rfpData, onUpdateRfpData, editMode = false }: RFPStepProps) => {
  const rfpOrganization = rfpData?.organization || {}
  const plants = rfpData?.plants || []
  const [jobPlantsMenuAnchorEl, setJobPlantsMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [jobMenuAnchorEl, setJobMenuAnchorEl] = useState<null | HTMLElement>(null)
  const [activeJobId, setActiveJobId] = useState('')
  const [selectedPlantIds, setSelectedPlantIds] = useState<Set<string>>(new Set())
  const [addPlantsFromJobModalOpen, setAddPlantsFromJobModalOpen] = useState(false)
  const [addPlantsFromListModalOpen, setAddPlantsFromListModalOpen] = useState(false)
  const [createPlantListModalOpen, setCreatePlantListModalOpen] = useState(false)
  const [addPlantsModalOpen, setAddPlantsModalOpen] = useState(false)
  const [editRfpDetailsModalOpen, setEditRfpDetailsModalOpen] = useState(false)
  const [confirmationModalContent, setConfirmationModalContent] = useState<ConfirmationModalProps | null>(null)
  const handleSelectionChange = useCallback((selectedIds: Set<string>) => {
    setSelectedPlantIds(selectedIds)
  }, [])

  if (!rfpData) {
    return null
  }

  const handlePlantMenuClick = (event: MouseEvent<HTMLElement>, jobId: string) => {
    setJobPlantsMenuAnchorEl(event.currentTarget)
    setActiveJobId(jobId)
  }

  const handleJobMenuClick = (event: MouseEvent<HTMLElement>, jobId: string) => {
    setJobMenuAnchorEl(event.currentTarget)
    setActiveJobId(jobId)
  }

  const jobGroups = rfpData?.jobs_in_plants?.reduce(
    (acc: Record<string, Job>, job: Job) => {
      acc[job.id] = job
      return acc
    },
    {} as Record<string, any>
  )

  const plantsByJobGroup = rfpData.plants.reduce(
    (acc: Record<string, PlantListEntry[]>, plant: PlantListEntry) => {
      const jobId = plant.job_id || 'null'
      if (!acc[jobId]) {
        acc[jobId] = []
      }
      acc[jobId].push(plant)
      return acc
    },
    {} as Record<string, PlantListEntry[]>
  )

  const handlePlantsUpdate = (updatedPlants: PlantListEntry[]) => {
    onUpdateRfpData({ plants: updatedPlants })
    setActiveJobId('')
  }

  const handleAddJob = () => {
    setActiveJobId('')
    setAddPlantsFromJobModalOpen(true)
  }

  const handleRemovePlants = () => {
    const updatedPlantEntries = [...(rfpData?.plants || [])].map((entry) => {
      if (selectedPlantIds.has(entry.id)) {
        return {
          ...entry,
          deleted_at: new Date().toISOString(),
        }
      }
      return entry
    })
    handlePlantsUpdate(updatedPlantEntries)
  }

  const isPlantWithQuantity = (plant: PlantWithQuantity | PlantListEntry): plant is PlantWithQuantity => {
    return (plant as PlantWithQuantity).plant !== undefined
  }

  const handleAddPlants = async (plants: PlantWithQuantity[] | PlantListEntry[]) => {
    const updatedPlantEntries = [...(rfpData?.plants || [])]

    for (const plant of plants) {
      const newId = generateObjectId()
      const jobId = activeJobId ? activeJobId : plant?.job_id || null
      let newPlant: PlantListEntry

      if (isPlantWithQuantity(plant)) {
        // Handle PlantWithQuantity type
        newPlant = {
          ...DEFAULT_PLANT_LIST_ENTRY,
          id: newId,
          job_id: jobId,
          quantity_count: { min: plant.quantity, max: null },
          scientific_name: plant.plant.scientific_name,
          common_name: plant.plant.common_names[0],
          parent_of_order: null,
        }
      } else {
        // Handle PlantListEntry type
        newPlant = {
          ...plant,
          job_id: jobId,
          parent_of_order: null,
        }
      }

      const currentLastEntry = updatedPlantEntries.find((entry) => entry.parent_of_order === null)
      if (currentLastEntry) {
        currentLastEntry.parent_of_order = isPlantWithQuantity(plant) ? newId : plant.id
      }
      updatedPlantEntries.push(newPlant)
    }

    setActiveJobId('')
    handlePlantsUpdate(updatedPlantEntries)
  }

  const AddMissingPlantsFromJobButton = ({ jobId }: { jobId: string }) => {
    const jobData = jobGroups[jobId]
    const jobPlantTotalCount = excludeDeletedItems(jobData?.plants).length
    const jobPlantInRfpCount = excludeDeletedItems(plantsByJobGroup[jobId]).length
    const missingPlantsCount = jobPlantTotalCount - jobPlantInRfpCount

    if (!missingPlantsCount) return null

    const handleMissingPlantClick = () => {
      setActiveJobId(jobId)
      setAddPlantsFromJobModalOpen(true)
    }

    return (
      <Stack
        direction="row"
        sx={{
          alignItems: 'center',
          py: 2,
          pl: 7.5,
          borderBottom: '1px solid',
          borderColor: theme.palette.lightGrey2.main,
        }}
      >
        <Stack direction="row" sx={{ cursor: 'pointer' }} onClick={handleMissingPlantClick}>
          <AddCircle sx={{ mr: 2, color: theme.palette.primary.main }} />
          <Typography color="textSecondary">{missingPlantsCount} Plants not included in this RFP</Typography>
        </Stack>
      </Stack>
    )
  }

  const RequestPricingHeader = () => {
    let title = ''
    const defaultTitle = 'Request Pricing for Plants'

    const validPlants = excludeDeletedItems(plants)

    if (validPlants.length > 0) {
      // should total quantity should be made based on min/max count ?
      // for now, priority is given to min quantity when available
      const totalItemCount = validPlants.reduce(
        (acc: number, plant: any) => acc + (plant.quantity_count?.min || plant.quantity_count?.max || 0),
        0
      )
      title = `Request Pricing for ${validPlants.length} Plants (${totalItemCount} Items)`
    }

    return (
      <Typography variant="body1" sx={{ fontWeight: 700, pl: 2 }}>
        {title || defaultTitle}
      </Typography>
    )
  }

  const JobPlantsMenu = () => {
    const jobData = jobGroups[activeJobId]
    const jobPlantTotalCount = excludeDeletedItems(jobData?.plants).length

    const handleClose = () => {
      setJobPlantsMenuAnchorEl(null)
    }

    const handleAddJobPlants = () => {
      handleClose()
      setAddPlantsFromJobModalOpen(true)
    }

    const handleAddPlantsFromList = () => {
      handleClose()
      setAddPlantsFromListModalOpen(true)
    }

    const handleAddPlantsManually = () => {
      handleClose()
      setAddPlantsModalOpen(true)
    }

    const handleAddPlantsFromFileUpload = () => {
      handleClose()
      setCreatePlantListModalOpen(true)
    }

    return (
      <Box>
        <Menu
          anchorEl={jobPlantsMenuAnchorEl}
          open={Boolean(jobPlantsMenuAnchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 200,
            },
          }}
        >
          <Typography variant="strong" sx={{ px: 2, py: 1 }}>
            {jobPlantTotalCount} Plants in this Job
          </Typography>
          <MenuItem onClick={handleAddJobPlants} disabled={jobPlantTotalCount === 0}>
            Select Plants to include in RFP ...
          </MenuItem>
          <Divider />
          <Typography variant="strong" sx={{ px: 2, py: 1 }}>
            Add New Plants to Job
          </Typography>
          <MenuItem onClick={handleAddPlantsFromList}>Add from Existing Plant List ...</MenuItem>
          <MenuItem onClick={handleAddPlantsFromFileUpload}>Add from File Upload ...</MenuItem>
          <MenuItem onClick={handleAddPlantsManually}>Add Manually ...</MenuItem>
        </Menu>
      </Box>
    )
  }

  const JobMenu = () => {
    const jobData = jobGroups[activeJobId]
    const plantsInJobCount = excludeDeletedItems(plantsByJobGroup[activeJobId]).length

    const handleClose = () => {
      setJobMenuAnchorEl(null)
    }

    const removePlantsFromJob = () => {
      const updatedPlantEntries = [...(rfpData?.plants || [])].map((entry) => {
        if (entry.job_id === activeJobId) {
          return {
            ...entry,
            deleted_at: new Date().toISOString(),
          }
        }
        return entry
      })
      handlePlantsUpdate(updatedPlantEntries)
      setConfirmationModalContent(null)
    }

    const handleRemoveJob = () => {
      handleClose()
      setConfirmationModalContent({
        open: true,
        title: 'Remove Job from RFP',
        message: `Are you sure you want to remove ${
          activeJobId === 'null'
            ? `the ${plantsInJobCount} unassigned plants from this RFP ?`
            : `${jobData.name} (Job #${activeJobId}) and its plants from this RFP ?`
        }`,
        onConfirm: removePlantsFromJob,
        onCancel: () => {
          setConfirmationModalContent(null)
        },
      })
    }

    const handleJobPhase = () => {
      console.log('Add a New Phase to Job')
      handleClose()
    }

    const handleHidePhases = () => {
      console.log("Hide This Job's Phases from Vendor")
      handleClose()
    }

    const handleRequestImages = () => {
      console.log('Request Images of All Items')
      handleClose()
    }

    return (
      <Box>
        <Menu
          anchorEl={jobMenuAnchorEl}
          open={Boolean(jobMenuAnchorEl)}
          onClose={handleClose}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'right',
          }}
          sx={{
            '& .MuiPaper-root': {
              minWidth: 200,
            },
          }}
        >
          <MenuItem onClick={handleRemoveJob}>Remove Job from RFP</MenuItem>
          <MenuItem onClick={handleJobPhase}>Add a New Phase to Job</MenuItem>
          <MenuItem onClick={handleHidePhases}>Hide This Job's Phases from Vendor</MenuItem>
          <MenuItem onClick={handleRequestImages}>Request Images of All Items</MenuItem>
        </Menu>
      </Box>
    )
  }

  return (
    <Box
      sx={{
        flexGrow: 1,
        height: '100%',
        backgroundColor: 'white',
        borderRadius: theme.borderRadius.lg,
      }}
    >
      <Stack
        sx={{
          backgroundColor: 'white',
          borderRadius: theme.borderRadius.lg,
          overflow: 'hidden',
          p: 5.5,
          gap: 3,
        }}
      >
        {/* Organization details & RFP# */}
        <Stack direction="row" sx={{ justifyContent: 'space-between' }}>
          <OrganizationDetails organization={rfpOrganization} />
          <Typography variant="h3" fontWeight={400} sx={{ color: theme.palette.mediumGrey2.main }}>
            RFP# {rfpData.id}
          </Typography>
        </Stack>

        {/* RFP Details & Job Details */}
        <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2 }}>
          <RFPDetails
            openEditRfpModal={() => {
              setEditRfpDetailsModalOpen(true)
            }}
            rfpData={rfpData}
            editMode={editMode}
          />
          <JobDetails rfpData={rfpData} />
        </Stack>

        {/* Plants & Actions */}
        <Stack>
          <Stack
            direction="row"
            sx={{
              justifyContent: 'space-between',
              mb: 1,
              alignItems: 'center',
            }}
          >
            {/* Header with title and summary of plants in table */}
            <RequestPricingHeader />

            {/* Bulk Actions & Add Plants - visible only in edit mode */}
            {editMode && (
              <Stack direction="row" sx={{ gap: 1 }}>
                <Button
                  variant="outlined"
                  aria-label="remove-plants-rfp"
                  onClick={handleRemovePlants}
                  disabled={selectedPlantIds.size === 0}
                  sx={{
                    color: theme.palette.darkGrey.main,
                    borderRadius: theme.borderRadius.sm,
                    borderColor: theme.palette.lightGrey2.main,
                  }}
                >
                  <Typography variant="button">Remove Selected from RFP</Typography>
                </Button>

                {/* Add Job */}
                <Button
                  variant="outlined"
                  aria-label="add-job-rfp"
                  onClick={handleAddJob}
                  sx={{
                    borderRadius: theme.borderRadius.sm,
                    borderColor: theme.palette.lightGrey2.main,
                    color: theme.palette.darkGrey.main,
                  }}
                >
                  <Add fontSize="small" />
                  <Typography variant="button">Add Job</Typography>
                </Button>
              </Stack>
            )}
          </Stack>

          <CustomDivider />

          {/* Plant Table */}
          {Object.entries(plantsByJobGroup).map(([jobId, plants]: [string, unknown], index: number) => {
            const jobData = jobGroups[jobId] || {}
            const expectedStartDate = formatStringToShortMonthDayYear(jobData.job_date) || 'TBD'
            return excludeDeletedItems(plants as PlantListEntry[]).length ? (
              <Stack key={`rfp-job-${jobId}`}>
                <Stack direction="row" sx={{ pt: 4, pb: 1, pl: 2, pr: 0, justifyContent: 'space-between' }}>
                  <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                    {jobId === 'null' ? (
                      <Typography variant="strong">Unassigned</Typography>
                    ) : (
                      <Stack direction="row" sx={{ gap: 1, alignItems: 'center' }}>
                        <Typography fontWeight={700} variant="body1">
                          Job #{jobId}
                        </Typography>
                        <Typography fontWeight={400} variant="body2">
                          -
                        </Typography>
                        <Typography fontWeight={400} variant="body2">
                          {jobGroups[jobId]?.name}
                        </Typography>
                      </Stack>
                    )}
                    <Typography fontWeight={400} variant="body2" color="textSecondary">
                      (Name not visible to vendors)
                    </Typography>
                  </Stack>
                  <Stack direction="row" sx={{ alignItems: 'center' }}>
                    {/* Expected Start Date */}
                    <Typography sx={{ mr: 2 }}>Expected Start Date: {expectedStartDate}</Typography>

                    {/* Plants Menu */}
                    <Button variant="contained" onClick={(e) => handlePlantMenuClick(e, jobId)}>
                      <Add fontSize="small" />
                      <Typography variant="button">Plants</Typography>
                    </Button>
                    <JobPlantsMenu />

                    {/* Job Menu */}
                    <IconButton
                      sx={{
                        px: 0,
                        py: 1,
                        ml: 1,
                        borderRadius: theme.borderRadius.sm,
                        border: '1px solid #ccc',
                        backgroundColor: '#EEF2f5',
                      }}
                      onClick={(e) => handleJobMenuClick(e, jobId)}
                    >
                      <EllipsisVertical />
                    </IconButton>
                    <JobMenu />
                  </Stack>
                </Stack>
                <CustomDivider />
                <RfpPlantTable
                  key={index}
                  plants={plants as PlantListEntry[]}
                  onUpdate={handlePlantsUpdate}
                  editMode={editMode}
                  onSelectionChange={handleSelectionChange}
                />
                {/* Add Plants missing from job */}
                {editMode && <AddMissingPlantsFromJobButton jobId={jobId} />}
              </Stack>
            ) : null
          })}
        </Stack>
        {/* Vendor Comments & Internal Notes */}
        <Stack direction="row" sx={{ justifyContent: 'space-between', gap: 2, mt: 3 }}>
          <RFPComments
            title="General Comments (for Vendors)"
            name="vendorComments"
            value={rfpData?.comments_for_vendor || ''}
            placeholder="Type comments here ..."
            onChange={(value) => onUpdateRfpData({ comments_for_vendor: value })}
            editMode={editMode}
          />
          {/* Internal Notes is visible only in edit mode (used in build rfp step) */}
          {editMode && (
            <RFPComments
              title="General Notes"
              name="generalNotes"
              value={rfpData?.notes || ''}
              placeholder="Type notes here ..."
              isInternal
              onChange={(value) => onUpdateRfpData({ notes: value })}
              editMode={editMode}
            />
          )}
        </Stack>
      </Stack>

      {/* Menu option modals */}
      <EditRfpDetailsModal
        rfpData={rfpData}
        open={editRfpDetailsModalOpen}
        onClose={() => {
          setEditRfpDetailsModalOpen(false)
        }}
        onSubmit={onUpdateRfpData}
      />
      <AddPlantsFromJobsModal
        open={addPlantsFromJobModalOpen}
        onClose={() => {
          setAddPlantsFromJobModalOpen(false)
          setActiveJobId('')
        }}
        jobId={activeJobId}
        onAddPlants={handleAddPlants}
      />
      <AddPlantsFromListModal
        open={addPlantsFromListModalOpen}
        onClose={() => setAddPlantsFromListModalOpen(false)}
        onAddPlants={handleAddPlants}
      />
      <CreatePlantListForRFPModal
        open={createPlantListModalOpen}
        onClose={() => setCreatePlantListModalOpen(false)}
        onAddPlants={handleAddPlants}
      />
      <AddPlantsModal
        open={addPlantsModalOpen}
        onClose={() => setAddPlantsModalOpen(false)}
        onAddPlants={handleAddPlants}
      />
      {confirmationModalContent && <ConfirmationModal {...confirmationModalContent} />}
    </Box>
  )
}
