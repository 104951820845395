import api from '@/api/api.ts'
import type { PlantListEntry } from '@/types.ts'

export async function createJob(name: string, date: string, plants?: PlantListEntry[], organizationId?: string) {
  if (!organizationId) {
    throw new Error('Cannot create a new job: No organization selected')
  }

  if (!plants || plants.length === 0) {
    throw new Error('Cannot create a new job: No plants selected')
  }

  const body = {
    organization: organizationId,
    name,
    job_date: date,
    plants,
  }

  const response = await api.post('/v1/core/job/', body, {
    headers: {
      'Content-Type': 'application/json',
    },
  })

  // TODO: type this response
  return response.data
}

export async function getJobsByOrganization(organizationId?: string) {
  if (!organizationId) {
    throw new Error('Cannot get jobs: No organization selected')
  }
  const response = await api.get(`/v1/core/job/?organization_id=${organizationId}`)
  return response.data
}

export async function getJobsById(organizationId?: string, jobId?: string) {
  if (!organizationId) {
    throw new Error('Cannot get jobs: No organization provided')
  } else if (!jobId) {
    throw new Error('Cannot get jobs: No job provided')
  }
  const response = await api.get(`/v1/core/job/${jobId}?organization_id=${organizationId}`)
  return response.data
}
